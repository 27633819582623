import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        trips: {data: [], current_page: 1, total: 0, per_page: 10},
        intermediate_trips: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                    description: []
                }
            }
        },
        has_errors: false,
        dialogVisible: false,
        loading: false
    },
    getters: {
        dialogVisible: state => state.dialogVisible,
        trips: state => state.trips,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
        loading: state => state.loading,
        intermediate_trips: state => state.intermediate_trips
    },
    mutations: {
        setTrips(state, data) {
            state.trips = data;
        },
        setIntermediateTrips(state, data) {
            state.intermediate_trips = data;
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        setDialogVisible(state, status) {
            state.dialogVisible = status;
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                            description: []
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getTrips({commit}, filters) {
            try {
                const response = await api.getTrips(filters);
                commit('setTrips', response);
            } catch (e) {
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },
        async createTrip({dispatch, commit}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                await api.createTrips(data);
                dispatch('getTrips', {
                    paginate: true
                })
                ElMessage({
                    type: 'success',
                    message: 'Trip created successfully!',
                    duration: 10000
                });
                commit('setLoading', false)
                commit('setDialogVisible', false)
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },

        async editTrip({dispatch, commit}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                await api.editTrip(data)
                ElMessage({
                    type: 'success',
                    message: 'Trip updated successfully!',
                    duration: 10000
                });
                commit('setLoading', false)
                commit('setDialogVisible', false)
                if(data.trip_id){
                    dispatch('getIntermediateTrips', {
                        paginate: true,
                        id:data.trip_id
                    })
                }else{
                    dispatch('getTrips', {
                        paginate: true
                    })
                }
            } catch (e) {
                commit('setLoading', false)
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },
        async deleteTrip({dispatch, commit}, data) {
            try {
                commit('hasErrors', false);
                const response = await api.deleteTrip(data);
                dispatch('getTrips', {
                    paginate: true
                });
                if (response.status === 401) {
                    ElMessage({
                        type: 'warning',
                        message: response.message,
                        duration: 10000
                    });
                } else {
                    ElMessage({
                        type: 'success',
                        message: response.message,
                        duration: 10000
                    });
                }
            } catch (e) {
                commit('hasErrors', true);
                commit('setErrors', e.response)
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        },

        async exportTrips(context, filters) {
            try {
                const response = await api.exportTrips(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'trips ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },

        async getIntermediateTrips({commit},data){
            try {
                const response = await api.getIntermediateTrips(data);
                commit('setIntermediateTrips', response);
            } catch (e) {
                ElMessage({
                    type: 'error',
                    message: e.response.data.message,
                    duration: 10000
                });
            }
        }

    }
}
