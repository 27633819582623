import client from "./client";

export default {
    async getCompanies(filters){
        return client.parseResponse(client.parseResponse(await client.get('/company', filters)));
    },

    async createCompanies(data){
        return client.parseResponse(await client.upload('/company', data, data.logo))
    },

    async getCompany(id){
        return client.parseResponse(await client.get('/company/' + id));
    },

    async editCompany(data){
        return client.parseResponse(await client.upload('/update-company/' + data.id, data, data.logo))
    },
    async deleteCompany(payload) {
        return client.parseResponse(await client.delete('/company/' + payload.id));
    },
    async uploadCompanies(payload) {
        return client.parseResponse(await client.upload('/import-companies', payload, payload.file))
    },
    async exportCompanies(filters) {
        return await client.get('/export-companies', filters, {responseType: 'blob'});
    },


    async getChildCompanies(filters){
        return client.parseResponse(client.parseResponse(await client.get('/parent-company', filters)));
    },
    async getTier1Companies(filters){
        return client.parseResponse(client.parseResponse(await client.get('/tier1-parent-companies', filters)));
    },
    async getTier2Companies(filters){
        return client.parseResponse(client.parseResponse(await client.get('/tier2-parent-companies', filters)));
    },

}
