<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Routes
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addRouteDialogVisible = true">Add Route
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div class="flex lg:flex-row flex-col justify-between md:items-start md:space-y-0 space-y-2 md:space-x-4 space-x-0 py-8 px-6 border-b">
            <div class="flex flex-row justify-start items-end mb-3 space-x-4">

                <!--        Export and Import-->
                <div class="flex flex-row">
                    <el-button-group>
                        <el-button @click="exportRoutes" type="success" plain>
                            <span class="mr-3">Export</span>
                            <el-icon>
                                <Download/>
                            </el-icon>
                        </el-button>
                        <el-button @click="uploadRouteDialogVisible=!uploadRouteDialogVisible" type="warning"
                                   size="default"
                                   plain>
                            <span class="mr-3">Import</span>
                            <el-icon>
                                <Upload/>
                            </el-icon>
                        </el-button>
                    </el-button-group>
                </div>
            </div>

            <form>
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 mr-3">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                        </svg>
                    </div>
                    <input v-model="search"
                           class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                           placeholder="Search Routes ...">
                </div>
            </form>
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Import dialog-->
            <el-dialog
                v-model="uploadRouteDialogVisible"
                title="Upload Routes"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-row items-center w-full rounded mb-4">
                        <a class="py-2 px-6 text-sky-600"
                           href="/templates/routes_template.csv" download="">
                            <font-awesome-icon class="mr-2 text-sm"
                                               :icon="['fas', 'download']"/>
                            Please click here to download the routes template.
                        </a>
                    </div>
                    <form class="px-4">
                        <div v-if="company && (company.tier === 'tier1' || company.tier === 'tier2')" class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Company</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.child_company_id"
                                        placeholder="Select company" size="default">
                                        <el-option
                                            v-for="item in child_companies"
                                            :key="item.id"
                                            :label="item.child.name"
                                            :value="item.child.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.child_company_id  && this.has_errors">
                                    {{ errors.data.errors.child_company_id[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row border p-2 rounded-md w-full">
                                    <input
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        class="outline-none w-full" @change="companyFile" type="file" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="upload">
                            Upload
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="uploadRouteDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addRouteDialogVisible"
                title="Add/Edit Routes"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div v-if="company && (company.tier === 'tier1' || company.tier === 'tier2')" class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Company</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.child_company_id"
                                        placeholder="Select company" size="default">
                                        <el-option
                                            v-for="item in child_companies"
                                            :key="item.id"
                                            :label="item.child.name"
                                            :value="item.child.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.child_company_id  && this.has_errors">
                                    {{ errors.data.errors.child_company_id[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Route Code<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.Route_Code" type="text"
                                        name="form.Route_Code" required/>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.Route_Code  && this.has_errors">
                                    {{ errors.data.errors.Route_Code[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Distance(Kms)<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.Distance" type="number"
                                        name="form.Distance" required/>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.Distance  && this.has_errors">
                                    {{ errors.data.errors.Distance[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Origin Town</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" filterable v-model="form.origin_town_id" placeholder="Select route"
                                        size="default">
                                        <el-option
                                            v-for="item in towns"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.origin_town_id  && this.has_errors">
                                    {{ errors.data.errors.origin_town_id[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Destination Town</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" filterable v-model="form.destination_town_id" placeholder="Select route"
                                        size="default">
                                        <el-option
                                            v-for="item in towns"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.destination_town_id  && this.has_errors">
                                    {{ errors.data.errors.destination_town_id[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Target Km/L</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.target_km_per_l" type="number"
                                        name="form.Distance" required/>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.target_km_per_l  && this.has_errors">
                                    {{ errors.data.errors.target_km_per_l[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Target Hrs</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.target_hrs" type="number"
                                        name="form.Distance" required/>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.target_hrs  && this.has_errors">
                                    {{ errors.data.errors.target_hrs[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Target Ltrs</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.target_ltrs" type="number"
                                        name="form.Distance" required/>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.target_ltrs  && this.has_errors">
                                    {{ errors.data.errors.target_ltrs[0] }}
                                </span>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addRoute">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger" @click="addRouteDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="routes.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="company.name" label="COMPANY"/>
                    <el-table-column prop="Route_Code" label="ROUTE CODE"/>
                    <el-table-column prop="Distance" label="DISTANCE(Kms)"/>
                    <el-table-column prop="origin_town.name" label="ORIGIN"/>
                    <el-table-column prop="destination_town.name" label="DESTINATION"/>
                    <el-table-column prop="target_km_per_l" label="TARGET KM/L"/>
                    <el-table-column prop="target_ltrs" label="TARGET LTRS"/>
                    <el-table-column prop="target_hrs" label="TARGET HRS"/>
                    <el-table-column prop="created_at" label="CREATED AT"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateRoute(scope.row)">EDIT</el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteRoute(scope.row)">DELETE
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="routes.current_page"
                    :page-size="routes.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="routes.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown, Upload, Download} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import company from "@/mixins/company";

export default {
    mixins: [company],
    data() {
        return {
            search: '',
            uploadRouteDialogVisible: false,
            addRouteDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            route: {},
            filters: {
                paginate: true,
                per_page: 10
            },
            user: {},
        };
    },
    components: {
        ArrowDown, Upload, Download

    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getRoutesList({
            ...this.filters,
            company_id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
        this.getCompanies(this.filters)
        this.getTowns()
    },
    computed: {
        models() {
            var model_data = this.routes.data
            return model_data?.map(function (item) {
                return item.model;
            }).filter(
                (item, index, arr) => {
                    return arr.indexOf(item) === index
                }
            );
        },
        ...mapGetters({
            routes: 'routes/routes',
            companies: 'companies/companies',
            towns:'towns/towns',
            errors:'routes/errors',
            has_errors:'routes/has_errors'
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getRoutesList({
                    ...this.filters,
                    company_id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                })
            }
        },
        search: function (val) {
            this.getRoutesList({
                ...this.filters,
                search: val,
                company_id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            });
        }
    },
    methods: {
        ...mapActions({
            getRoutesList: 'routes/getRoutes',
            uploadRoutes: 'routes/uploadRoutes',
            downloadRoutes: 'routes/exportRoutes',
            createRoutes: 'routes/createRoutes',
            editRoute: 'routes/editRoute',
            getCompanies: 'companies/getCompanies',
            getTowns:'towns/getTowns',
            removeRoute:'routes/deleteRoute'
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getRoutesList({paginate: true, ...this.filters,
                company_id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            });
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getRoutesList({paginate: true, ...this.filters,
                company_id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id});
        },
        addRoute() {
            this.form.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            if (this.form.id) {
                this.editRoute(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addRouteDialogVisible = false
                    }
                })
            } else {
                this.createRoutes(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addRouteDialogVisible = false
                    }
                });
            }
        },
        updateRoute(item) {
            this.addRouteDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        deleteRoute(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.removeRoute({
                    id: item.id,
                    company_id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                })
            }
        },
        upload() {
            this.route.file = this.file;
            this.route.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            this.uploadRoutes(this.route)
                .then(() => {
                    this.getRoutesList({
                        ...this.filters,
                        company_id:this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                    })
                    this.uploadRouteDialogVisible = false
                    this.$message.success('Routes uploaded successfully!')
                }).catch((e) => {
                this.$message.error(e.response.data.message)
            })
        },
        companyFile(e) {
            this.file = e.target.files[0]
        },
        exportRoutes() {
            this.downloadRoutes({...this.filters})
        }

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
