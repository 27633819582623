import {mapActions, mapGetters} from "vuex";
export default {
    data() {
        return {
            user: {},
        };
    },
    computed: {
        companyId() {
            // Check if the company ID exists in the route params, else fallback to the user's company ID
            return this.user.user_company.company_id ? this.user.user_company.company_id : this.$route.params.id;
        },
        ...mapGetters({
            company:'companies/company',
            child_companies:'companies/child_companies'
        })
    },
    methods: {
        ...mapActions({
            getCompany: 'companies/getCompany',
            getChildCompanies:'companies/getChildCompanies'
        }),
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        // Fetch company data when the component is mounted
        this.getCompany(this.companyId).then(()=>{
            // If the company is tier1, fetch child companies
            if (this.company.tier === 'tier1' || this.company.tier === 'tier2') {
                this.getChildCompanies({
                    id:this.companyId
                }).then(()=>{
                    console.log(this.child_companies, 'here')
                })
            }
        })
    },
};
