<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div class="relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Tyre History
                    </h3>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div class="flex lg:flex-row flex-col justify-between md:items-start md:space-y-0 space-y-2 md:space-x-4 space-x-0 py-8 px-6 border-b">
            <div class="block">
                <span class="text-black mr-2">Date Range</span>
                <el-date-picker
                    v-model="date_range"
                    type="datetimerange"
                    :shortcuts="shortcuts"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                />
            </div>

            <div class="flex flex-row justify-start items-end mb-3 space-x-4">
                <!--        Export and Import-->
                <div class="flex flex-row">
                    <el-button @click="exportTyreLogs" type="success" plain>
                        <span class="mr-3">Export as Excel</span>
                        <el-icon>
                            <Download/>
                        </el-icon>
                    </el-button>
                </div>
            </div>
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="tyre_logs.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="vehicle.company.name" label="COMPANY"/>
                    <el-table-column prop="tyre.tyre_serial_number" label="TYRE SERIAL NO."/>
                    <el-table-column prop="tyre_operation.name" label="OPERATION"/>
                    <el-table-column prop="cost" label="COST"/>
                    <el-table-column prop="vehicle.reg_number" label="VEHICLE REG NO."/>
                    <el-table-column prop="user.full_name" label="USER"/>
                    <el-table-column prop="tyre_status" label="TYRE STATUS"/>
                    <el-table-column prop="created_at" label="DATETIME"/>
                    <!--                    <el-table-column label="STATUS">-->
                    <!--                        <template #default="scope">-->
                    <!--                            <el-tag effect="dark" :type="scope.row.status === 'new' ? 'warning' : scope.row.status === 'in progress' ? '' : 'success'">-->
                    <!--                                {{ scope.row.status}}-->
                    <!--                            </el-tag>-->
                    <!--                        </template>-->
                    <!--                    </el-table-column>-->
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="tyre_logs.current_page"
                    :page-size="tyre_logs.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tyre_logs.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {Download} from '@element-plus/icons-vue'
import moment from "moment/moment";

export default {
    data() {
        return {
            filters: {
                paginate: true,
                per_page: 10
            },
            date_range:[],
            shortcuts:[
                {
                    text: 'Today',
                    value: new Date(),
                },
                {
                    text: 'Yesterday',
                    value: () => {
                        const date = new Date()
                        date.setTime(date.getTime() - 3600 * 1000 * 24)
                        return date
                    },
                },
                {
                    text: 'This week',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setHours(0, 0, 0, 0); // Start of the current day
                        const dayOfWeek = start.getDay(); // 0 (Sunday) to 6 (Saturday)
                        const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
                        start.setDate(start.getDate() - daysToSubtract);
                        return [start, end];
                    },
                },
                {
                    text: 'Last week',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]
                    },
                },
                {
                    text: 'This month',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setDate(1); // Start of the current month
                        return [start, end];
                    },
                },
                {
                    text: 'Last month',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        return [start, end]
                    },
                },
                {
                    text: 'Last 3 months',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                        return [start, end]
                    },
                },
                {
                    text: 'This year',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(0, 1); // Start of the current year
                        return [start, end];
                    },
                },
            ]
        }
    },
    components: {
        Download

    },
    mounted() {
        this.getTyreLogs({
            ...this.filters,
        })
        console.log('here')
    },
    computed: {
        ...mapGetters({
            tyre_logs : 'tyre_logs/all_tyre_logs'
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getTyreLogs({
                    ...this.filters,
                })
            }
        },
        'date_range': function (val){
            this.filters.start = moment(val[0]).format('DD-MM-YYYY HH:MM')
            this.filters.end = moment(val[1]).format('DD-MM-YYYY HH:MM')
            this.getTyreLogs({
                ...this.filters,
            })
        },
    },
    methods: {
        ...mapActions({
            getTyreLogs: 'tyre_logs/getAllTyreLogs',
            downloadTyreLogs: 'tyre_logs/exportAllTyreLogs',
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getTyreLogs({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getTyreLogs({paginate: true, ...this.filters});
        },
        exportTyreLogs() {
            this.downloadTyreLogs({...this.filters})
        },

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
