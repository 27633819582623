<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div
                    class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        All Users
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="openDialog(true)">Add User
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div
            class="flex md:flex-row flex-col justify-between md:items-end py-5 text-gray-100 border-b px-4 md:space-y-0 space-y-2 md:space-x-4 space-x-0">
            <div
                class="flex md:flex-row flex-col justify-start items-end mb-3  md:space-y-0 space-y-2 md:space-x-4 space-x-0 md:w-8/12 w-full">

                <div class="flex flex-col w-full">
                    <p class="text-md font-bold text-sm lg:text-md mb-3 text-black">Status</p>
                    <div class="flex flex-row items-start justify-start w-full">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" multiple v-model="filters.status" placeholder="Select status"
                                size="default">
                                <el-option>Active</el-option>
                                <el-option>Inactive</el-option>
                            </el-select>
                        </div>
                    </div>
                </div>
                <!--        Export and Import-->
                <div class="flex flex-row w-full">
                    <el-button-group>
                        <el-button @click="exportUsers" type="success" plain>
                            <span class="mr-3">Export</span>
                            <el-icon>
                                <Download/>
                            </el-icon>
                        </el-button>
                        <el-button @click="uploadUserDialogVisible = true" type="warning"
                                   size="default"
                                   plain>
                            <span class="mr-3">Import</span>
                            <el-icon>
                                <Upload/>
                            </el-icon>
                        </el-button>
                    </el-button-group>
                </div>
            </div>

            <form>
                <div class="relative text-black">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 mr-3">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                        </svg>
                    </div>
                    <input v-model="search"
                           class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                           placeholder="Search Users ...">
                </div>
            </form>
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Import dialog-->
            <el-dialog
                v-model="uploadUserDialogVisible"
                title="Upload Users"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-row items-center w-full rounded mb-4">
                        <a class="py-2 px-6 text-sky-600"
                           href="/templates/users_template.csv" download="">
                            <font-awesome-icon class="mr-2 text-sm"
                                               :icon="['fas', 'download']"/>
                            Please click here to download the users template.
                        </a>
                    </div>
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Company</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="user.company_id" placeholder="Select company"
                                        size="default">
                                        <el-option
                                            v-for="item in companies.data"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row border p-2 rounded-md w-full">
                                    <input
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        class="outline-none w-full" @change="companyFile" type="file" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button type="primary" @click="upload">
                            Upload
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger" @click="uploadUserDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addUserDialogVisible"
                title="Add/Edit Users"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">First Name<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.first_name" type="text"
                                        name="form.first_name" required/>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Last Name<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.last_name" type="text"
                                        name="form.last_name" required/>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Email<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.email" type="email"
                                        name="form.email" required/>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Phone Number<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <MazPhoneNumberInput
                                        v-model="phone"
                                        default-country-code="KE"
                                        :only-countries="['KE','TZ','UG','RW','BW','ZA','NA','ZW']"
                                        @update="emitInput"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Nfc Tag</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.nfc_tag" type="text"
                                        name="form.last_name" required/>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Company<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select @change="populateCompanyBranches"
                                               style="width: 100%" v-model="form.company_id"
                                               placeholder="Select company"
                                               size="default">
                                        <el-option
                                            v-for="item in companies.data"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Company Branch<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.company_branch_id"
                                        placeholder="Select company branch"
                                        size="default">
                                        <el-option
                                            v-for="item in company_branches"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Address One<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.address_one" type="text"
                                        name="form.Distance" required/>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Address Two<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.address_two" type="text"
                                        name="form.Distance" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addUser">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger" @click="openDialog(false)">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="users.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="full_name" label="FULL NAME"/>
                    <el-table-column prop="email" label="EMAIL"/>
                    <el-table-column prop="phone_number" label="PHONE NUMBER"/>
                    <el-table-column prop="address_one" label="ADDRESS"/>
                    <el-table-column prop="address_two" label="ADDRESS 2"/>
                    <el-table-column prop="is_admin" label="IS ADMIN"/>
                    <el-table-column prop="user_company.company.name" label="COMPANY"/>
                    <el-table-column label="STATUS">
                        <template #default="scope">
                            {{ scope.row.status === 1 ? 'Active' : 'Inactive' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="created_at" label="CREATED AT"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateUser(scope.row)">EDIT</el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteUser(scope.row)">DELETE
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="assignRole(scope.row)">ASSIGN ROLE
                                        </el-dropdown-item>
                                        <el-dropdown-item v-if="scope.row.status === 1" @click.native="disableUser(scope.row)">DISABLE USER
                                        </el-dropdown-item>
                                        <el-dropdown-item v-else @click.native="disableUser(scope.row)">ACTIVATE USER
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="users.current_page"
                    :page-size="users.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="users.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>

            <!--      Roles dialog-->
            <el-dialog
                v-model="assignRolesDialogVisible"
                title="Roles"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-col">
                        <div class="modal-body">
                            <div v-for="(role, index) in roles_p" :key="index">
                                <div class="flex px-2 py-2 my-2 items-center">
                                    <el-checkbox
                                        :id="'check'+index"
                                        v-model="role.status">
                                    </el-checkbox>
                                    <label class="font-medium px-6 text-lg" :for="'check'+index">
                                        {{ role.name }}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button type="primary" @click="addRole">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="assignRolesDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown, Upload, Download} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    data() {
        return {
            search: '',
            uploadUserDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {
                phone_number: '',
                country_code: ''
            },
            user: {},
            phone: null,
            filters: {
                paginate: true,
                per_page: 10
            },
            assignRolesDialogVisible: false
        };
    },
    components: {
        ArrowDown, Upload, Download

    },
    mounted() {
        this.getUsersList(this.filters)
        this.getCompanies(this.filters)
        this.getCompanyBranchesList({})
    },
    computed: {
        roles_p() {
            return this.roles.data.map(function (item) {
                item.status = item.status == 1 ? true : false;
                return item;
            });
        },
        ...mapGetters({
            users: 'users/users',
            companies: 'companies/companies',
            roles: 'users/user_roles',
            addUserDialogVisible: 'users/addUserDialogVisible',
            company_branches: 'company_branches/company_branches',
        }),
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getUsersList(this.filters)
            }
        },
        search: function (val) {
            this.getUsersList({
                ...this.filters,
                search: val
            });
        }
    },
    methods: {
        ...mapActions({
            getUsersList: 'users/getUsers',
            uploadUsers: 'users/uploadUsers',
            downloadUsers: 'users/exportUsers',
            createUsers: 'users/createUsers',
            editUser: 'users/editUser',
            getCompanies: 'companies/getCompanies',
            removeUser: 'users/deleteUser',
            getUserRoles: 'users/getUserRoles',
            saveUserRoles: 'users/saveUserRoles',
            openAddUserDialogVisible: 'users/openAddUserDialogVisible',
            getCompanyBranchesList: 'company_branches/getCompanyBranches',
            deactivateUser: 'users/disableUser'
        }),
        populateCompanyBranches(company_id) {
            this.getCompanyBranchesList({
                id: company_id
            })
        },
        openDialog(status) {
            this.openAddUserDialogVisible(status);

        },
        emitInput(input) {
            if (input.isValid) {
                this.form.phone_number = input.nationalNumber
                this.form.country_code = input.countryCallingCode
            } else {
                this.form.phone_number = null;
                this.form.country_code = null;

            }
        },
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getUsersList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getUsersList({paginate: true, ...this.filters});
        },
        addUser() {
            if (this.form.id) {
                this.editUser(this.form)
            } else {
                this.createUsers(this.form)
            }
        },
        updateUser(item) {
            this.openAddUserDialogVisible(true)
            this.form = Object.assign({}, this.form, item);
            this.form.company_id = item.user_company?.company.id
            this.phone = this.form.phone_number
            this.populateCompanyBranches(this.form.company_id)
        },
        deleteUser(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.removeUser({
                    id: item.id
                })
            }
        },
        upload() {
            this.user.file = this.file;
            this.uploadUsers(this.user)
                .then(() => {
                    this.getUsersList(this.filters)
                    this.uploadUserDialogVisible = false
                    this.$message.success('Users uploaded successfully!')
                }).catch((e) => {
                this.$message.error(e.response.data.message)
            })
        },
        companyFile(e) {
            this.file = e.target.files[0]
        },
        exportUsers() {
            this.downloadUsers({...this.filters})
        },
        assignRole(user) {
            this.user = user
            this.getUserRoles(user).then(() => {
                this.assignRolesDialogVisible = true
            })
        },
        addRole() {
            this.saveUserRoles({
                ...this.user,
                roles: this.roles_p
            }).then(() => {
                this.assignRolesDialogVisible = false
            })

        },
        closeModal() {
            this.openAddUserDialogVisible(false);
        },
        disableUser(user) {
            let conf = confirm(`Are you sure you want to change the status of this user?`)
            if (conf) {
                this.deactivateUser({
                    id: user.id
                })
            }
        }
    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
