import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        company_branches: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                    child_company_id:[],
                    address:[],
                    forward_sequence:[],
                    forward_mandatory:[],
                    return_sequence:[],
                    return_mandatory:[]
                }
            }
        },
        has_errors: false,
    },
    getters: {
        company_branches: state => state.company_branches,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setCompanyBranches(state, data) {
            state.company_branches = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                            child_company_id:[],
                            address:[],
                            forward_sequence:[],
                            forward_mandatory:[],
                            return_sequence:[],
                            return_mandatory:[]
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getCompanyBranches({commit}, filters) {
            try {
                const response = await api.getCompanyBranches(filters);
                commit('setCompanyBranches', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createCompanyBranches({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                await api.createCompanyBranches(data);
                dispatch('getCompanyBranches', {
                    id:data.company_id,
                    paginate: true
                })

                ElMessage({
                    message: 'CompanyBranch created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating CompanyBranch!',
                    type: 'error',
                })
            }
        },
        async editCompanyBranch({dispatch}, data) {
            try {
                await api.editCompanyBranch(data);
                dispatch('getCompanyBranches', {
                    id:data.company_id,
                    paginate: true
                });
                ElMessage({
                    message: 'CompanyBranch updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating CompanyBranch',
                    type: 'error',
                })
            }
        },
        async deleteCompanyBranch({dispatch}, payload) {
            try {
                const {message} = await api.deleteCompanyBranch(payload)
                dispatch('getCompanyBranches', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
        async uploadCompanyBranches(context, payload) {
            return await api.uploadCompanyBranches(payload)
        },
        async exportCompanyBranches(context, filters) {
            try {
                const response = await api.exportCompanyBranches(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'company_branches ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },

    }
}
