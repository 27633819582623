import {createStore} from 'vuex'
import companies from './companies.store';
import vehicles from "@/data/store/vehicles.store";
import tyres from "@/data/store/tyres.store";
import tyre_stores from "@/data/store/tyre_stores.store";
import tyre_sizes from "@/data/store/tyre_sizes.store";
import tyre_axels from "@/data/store/tyre_axels.store";
import tyre_brands from "@/data/store/tyre_brands.store";
import tyre_models from "@/data/store/tyre_models.store";
import tyre_specifications from "@/data/store/tyre_specifications.store";
import tyre_tread_patterns from "@/data/store/tyre_tread_patterns.store";
import tyre_vendors from "@/data/store/tyre_vendors.store";
import manufactures from "@/data/store/manufacturers.store";
import towns from "@/data/store/towns.store";
import routes from "@/data/store/routes.store";
import fleets from "@/data/store/fleets.store";
import tyre_defects from "@/data/store/tyre_defects.store";
import company_vehicles from "@/data/store/company_vehicles.store";
import company_routes from "@/data/store/company_routes.store";
import company_commodities from "@/data/store/company_commodities.store";
import auth from "@/data/store/auth.store";
import users from "@/data/store/users.store";
import roles from "@/data/store/roles.store";
import tyre_puncture_repairs from "@/data/store/tyre_puncture_repairs.store";
import modules from "@/data/store/modules.store";
import commodities from "@/data/store/commodities.store";
import vehicle_types from "@/data/store/vehicle_types.store";
import dismount_reasons from "@/data/store/dismount_reasons.store";
import inspections from "@/data/store/inspections.store";
import inspection_question_types from "@/data/store/inspection_question_types.store";
import inspection_questions from "@/data/store/inspection_questions.store";
import vehicle_inspections from "@/data/store/vehicle_inspections.store";
import inspection_logs from "@/data/store/inspection_logs.store";
import defects from "@/data/store/defects.store";
import permissions from "@/data/store/permissions.store"
import company_branches from "@/data/store/company_branches.store"
import tyre_logs from "@/data/store/tyre_logs.store"
import sms_users from "@/data/store/sms_users.store";
import app_settings from "@/data/store/app_settings.store"
import customers from "@/data/store/customers.store"
import journeys from "@/data/store/journeys.store"
import incentives from "@/data/store/incentives.store";
import configs from "@/data/store/configs.store";
import trips from "@/data/store/trips.store"

export default createStore({
    modules: {
        users,
        auth,
        companies,
        vehicles,
        tyres,
        tyre_stores,
        tyre_sizes,
        tyre_axels,
        tyre_models,
        tyre_brands,
        tyre_specifications,
        tyre_tread_patterns,
        tyre_vendors,
        towns,
        manufactures,
        routes,
        fleets,
        tyre_defects,
        company_vehicles,
        company_routes,
        company_commodities,
        roles,
        tyre_puncture_repairs,
        modules,
        commodities,
        vehicle_types,
        dismount_reasons,
        inspections,
        inspection_question_types,
        inspection_questions,
        vehicle_inspections,
        inspection_logs,
        defects,
        permissions,
        company_branches,
        tyre_logs,
        sms_users,
        app_settings,
        customers,
        journeys,
        incentives,
        configs,
        trips
    }
})
