<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div class="relative w-full md:flex-row md:space-y-0 space-y-2 flex-col px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-medium text-base">
                        TyreStores
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addTyreStoreDialogVisible = true">Add TyreStore
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div class="flex flex-col py-5 text-gray-100 border-b px-4">
            <div class="flex md:flex-row flex-col justify-between md:items-end mb-3 md:space-x-4 space-y-2">

                <!--        Export and Import-->
                <div class="flex flex-row">
                    <el-button-group>
                        <el-button @click="exportTyreStores" type="success" plain>
                            <span class="mr-3">Export</span>
                            <el-icon>
                                <Download/>
                            </el-icon>
                        </el-button>
                        <el-button @click="uploadTyreStoreDialogVisible=!uploadTyreStoreDialogVisible" type="warning"
                                   size="default"
                                   plain>
                            <span class="mr-3">Import</span>
                            <el-icon>
                                <Upload/>
                            </el-icon>
                        </el-button>
                    </el-button-group>
                </div>
                <!--      search-->
                <form>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="w-5 h-5 mr-3">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <input v-model="search"
                               class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                               placeholder="Search Tyre Stores ...">
                    </div>
                </form>
            </div>
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Import dialog-->
            <el-dialog
                v-model="uploadTyreStoreDialogVisible"
                title="Upload Tyre Stores"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-row items-center w-full rounded mb-4">
                        <a class="py-2 px-6 text-sky-600"
                           href="/templates/tyre_stores_template.csv" download="">
                            <font-awesome-icon class="mr-2 text-sm"
                                               :icon="['fas', 'download']"/>
                            Please click here to download the tyre_stores template.
                        </a>
                    </div>
                    <form class="px-4">
                        <div v-if="company && (company.tier === 'tier1' || company.tier === 'tier2')" class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Company</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.child_company_id"
                                        placeholder="Select company" size="default">
                                        <el-option
                                            v-for="item in child_companies"
                                            :key="item.id"
                                            :label="item.child.name"
                                            :value="item.child.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.child_company_id  && this.has_errors">
                                    {{ errors.data.errors.child_company_id[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row border p-2 rounded-md w-full">
                                    <input
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        class="outline-none w-full" @change="companyFile" type="file" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="upload">
                            Upload
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="uploadTyreStoreDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addTyreStoreDialogVisible"
                title="Add/Edit Tyre Stores"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div v-if="company && (company.tier === 'tier1' || company.tier === 'tier2')" class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Company</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.child_company_id"
                                        placeholder="Select company" size="default">
                                        <el-option
                                            v-for="item in child_companies"
                                            :key="item.id"
                                            :label="item.child.name"
                                            :value="item.child.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.child_company_id  && this.has_errors">
                                    {{ errors.data.errors.child_company_id[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Store Name
                                <span class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.store_name" type="text"
                                        name="form.name" required/>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Store Code
                                <span class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.store_code" type="text"
                                        name="form.name"/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addTyreStore">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="addTyreStoreDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="tyre_stores.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="company.name" label="COMPANY"/>
                    <el-table-column prop="store_name" label="STORE NAME"/>
                    <el-table-column prop="store_code" label="STORE CODE"/>
                    <el-table-column prop="created_at" label="CREATED AT"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateTyreStore(scope.row)">EDIT
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="viewTyresInStore(scope.row)">TYRES IN STORE
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteTyreStore(scope.row)">DELETE
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="tyre_stores.current_page"
                    :page-size="tyre_stores.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tyre_stores.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown, Upload, Download} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import company from '@/mixins/company';

export default {
    mixins: [company],
    data() {
        return {
            search: '',
            uploadTyreStoreDialogVisible: false,
            addTyreStoreDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            tyre_store: {},
            filters: {
                paginate: true,
                per_page: 10
            },
            user: {}
        };
    },
    components: {
        ArrowDown, Upload, Download

    },
    mounted() {
        this.getTyreStoresList({...this.filters,
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id})
        this.getUser()
    },
    computed: {
        models() {
            var model_data = this.tyre_stores.data
            return model_data?.map(function (item) {
                return item.model;
            }).filter(
                (item, index, arr) => {
                    return arr.indexOf(item) === index
                }
            );
        },
        ...mapGetters({
            tyre_stores: 'tyre_stores/tyre_stores',
            companies: 'companies/companies',
            errors:'tyre_stores/errors',
            has_errors:'tyre_stores/has_errors'
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getTyreStoresList({...this.filters,
                    id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id})
            }
        },
        search: function (val) {
            this.getTyreStoresList({
                ...this.filters,
                search: val,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            });
        }
    },
    methods: {
        ...mapActions({
            getTyreStoresList: 'tyre_stores/getTyreStores',
            uploadTyreStores: 'tyre_stores/uploadTyreStores',
            downloadTyreStores: 'tyre_stores/exportTyreStores',
            createTyreStores: 'tyre_stores/createTyreStores',
            editTyreStore: 'tyre_stores/editTyreStore',
            removeTyreModel: 'tyre_stores/deleteTyreStore'
        }),
        getUser() {
            this.user = JSON.parse(localStorage.getItem('user'));
        },
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getTyreStoresList({paginate: true, ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getTyreStoresList({paginate: true, ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id});
        },
        addTyreStore() {
            this.form.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            if (this.form.id) {
                this.editTyreStore(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addTyreStoreDialogVisible = false
                    }
                })
            } else {
                this.createTyreStores(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addTyreStoreDialogVisible = false
                    }
                });
            }
        },
        updateTyreStore(item) {
            this.addTyreStoreDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        deleteTyreStore(item) {
            let conf = confirm(`Are yous sure you want to remove this tyre store?`)
            if (conf) {
                this.removeTyreModel({
                    id: item.id
                })
            }
        },
        upload() {
            this.tyre_store.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            this.tyre_store.file = this.file;
            this.uploadTyreStores(this.tyre_store)
                .then(() => {
                    this.getTyreStoresList(this.filters)
                    this.uploadTyreStoreDialogVisible = false
                    this.$message.success('TyreStores uploaded successfully!')
                }).catch((e) => {
                this.$message.error(e.response.data.message)
            })
        },
        companyFile(e) {
            this.file = e.target.files[0]
        },
        exportTyreStores() {
            this.downloadTyreStores({...this.filters})
        },
        viewTyresInStore(item){
            this.$router.push({
                path:'/tyres',
                query:{
                    store_id:item.id
                }
            })
        }
    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
