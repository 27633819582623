import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        configs: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                    working_hours_start:[],
                    working_hours_end:[],
                    journeys_prefix:[],
                    child_company_id:[]
                }
            }
        },
        has_errors: false,
    },
    getters: {
        configs: state => state.configs,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
    },
    mutations: {
        setConfigs(state, data) {
            state.configs = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                            working_hours_start:[],
                            working_hours_end:[],
                            journeys_prefix:[],
                            child_company_id:[]
                        }
                    }
                };
            }
        }
    },
    actions: {
        async getConfigs({commit}, filters) {
            try {
                const response = await api.getConfigs(filters);
                commit('setConfigs', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createConfigs({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                await api.createConfigs(data);
                dispatch('getConfigs', {
                    paginate: true,
                    id:data.company_id
                })

                ElMessage({
                    message: 'Config created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating Config!',
                    type: 'error',
                })
            }
        },
        async editConfig({dispatch}, data) {
            try {
                await api.editConfig(data);
                dispatch('getConfigs', {
                    paginate: true,
                    id:data.company_id
                });
                ElMessage({
                    message: 'Config updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating Config',
                    type: 'error',
                })
            }
        },
        async deleteConfig({dispatch}, payload) {
            try {
                const {message} = await api.deleteConfig(payload)
                dispatch('getConfigs', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
    }
}
