import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        users: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    first_name: [],
                    last_name: [],
                    email: [],
                    phone_number: [],
                    country_code: [],
                    address_one: [],
                    address_two: []
                }
            }
        },
        user_roles: {data: [], current_page: 1, total: 0, per_page: 10},
        has_errors: false,
        addUserDialogVisible: false,
        loading:false
    },
    getters: {
        loading: state => state.loading,
        addUserDialogVisible: state => state.addUserDialogVisible,
        users: state => state.users,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
        user_roles: state => state.user_roles
    },
    mutations: {
        setLoading(state, status) {
            state.loading = status
        },
        setAddUserDialogVisible(state, data) {
            state.addUserDialogVisible = data;
        },
        setUserRoles(state, data) {
            state.user_roles = data;
        },
        setUsers(state, data) {
            state.users = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                        }
                    }
                };
            }
        }
    },
    actions: {
        openAddUserDialogVisible({commit}, status) {
            commit('setAddUserDialogVisible', status);
        },
        async getUsers({commit}, filters) {
            try {
                const response = await api.getUsers(filters);
                commit('setUsers', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createUsers({commit, dispatch}, data) {
            try {
                commit('setLoading', true)
                commit('hasErrors', false)
                await api.createUsers(data);
                dispatch('getUsers', {
                    paginate: true
                })
                commit('setLoading', false)
                commit('setAddUserDialogVisible', false)
                ElMessage({
                    message: 'User created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating User!',
                    type: 'error',
                })
            }
        },
        async editUser({dispatch, commit}, data) {
            try {
                await api.editUser(data);
                dispatch('getUsers', {
                    paginate: true
                });
                commit('setAddUserDialogVisible', false)
                ElMessage({
                    message: 'User updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating User',
                    type: 'error',
                })
            }
        },
        async deleteUser({dispatch}, payload) {
            try {
                const {message} = await api.deleteUser(payload)
                dispatch('getUsers', {})
                ElMessage({
                    message: message,
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
        async uploadUsers(context, payload) {
            return await api.uploadUsers(payload)
        },
        async exportUsers(context, filters) {
            try {
                const response = await api.exportUsers(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'users ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },

        async getUserRoles({commit}, filters) {
            try {
                const response = await api.getUserRoles(filters);
                commit('setUserRoles', response);
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: "error"
                });
            }
        },
        async saveUserRoles({dispatch}, data) {
            try {
                await api.saveUserRoles(data);
                dispatch('getUserRoles', {
                    paginate: true,
                    ...data
                });
                ElMessage({
                    message: 'User roles added successfully!',
                    type: "success"
                });
            } catch (e) {
                ElMessage({
                    message: e.response.data.message,
                    type: "error"
                });
            }
        },
        async disableUser({dispatch}, data) {
            try {
                await api.disableUser(data);
                dispatch('getUsers', {
                    paginate: true
                });
                ElMessage({
                    message: 'User disabled successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating User',
                    type: 'error',
                })
            }
        },

    }
}
