<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div
                    class="relative md:flex-row flex-col space-y-2 w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Tyres
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="openDialog(true)">Add Tyre
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div class="flex flex-col py-5 text-gray-900 border-b px-4 md:space-y-0 space-y-2">
            <div class="flex lg:flex-row flex-col justify-start md:items-end mb-6 lg:space-x-4 md:space-y-0 space-y-2">
                <div class="flex flex-col">
                    <p class="text-md font-bold text-sm lg:text-md mb-3">Company</p>
                    <div class="flex flex-row items-start justify-start w-full">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" multiple v-model="filters.company_id"
                                placeholder="Select company"
                                size="default">
                                <el-option
                                    v-for="item in child_companies"
                                    :key="item.id"
                                    :label="item.child.name"
                                    :value="item.child.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col">
                    <p class="text-md font-bold text-sm lg:text-md mb-3">Vehicle</p>
                    <div class="flex flex-row items-start justify-start w-full">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" multiple v-model="filters.vehicle_id"
                                placeholder="Select vehicle"
                                size="default">
                                <el-option
                                    v-for="item in vehicles"
                                    :key="item.id"
                                    :label="item.reg_number"
                                    :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col">
                    <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Store</p>
                    <div class="flex flex-row items-start justify-start w-full">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" v-model="filters.tyre_store_id"
                                placeholder="Filter by tyre store"
                                size="default">
                                <el-option
                                    v-for="item in tyre_stores"
                                    :key="item.id"
                                    :label="item.store_name"
                                    :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col">
                    <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Size</p>
                    <div class="flex flex-row items-start justify-start w-full">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" multiple v-model="filters.tyre_size_id"
                                placeholder="Filter by tyre size"
                                size="default">
                                <el-option
                                    v-for="item in tyre_sizes"
                                    :key="item.id"
                                    :label="item.size_name"
                                    :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col">
                    <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Brand</p>
                    <div class="flex flex-row items-start justify-start w-full">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" multiple v-model="filters.tyre_brand_id"
                                placeholder="Select tyre brand"
                                size="default">
                                <el-option
                                    v-for="item in tyre_brands"
                                    :key="item.id"
                                    :label="item.brand_name"
                                    :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>

            </div>

            <div class="flex lg:flex-row flex-col justify-start md:items-end mb-6 lg:space-x-4 md:space-y-0 space-y-2">
                <div class="flex flex-col">
                    <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Specification</p>
                    <div class="flex flex-row items-start justify-start w-full">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" multiple v-model="filters.tyre_specification_id"
                                placeholder="Select tyre specification"
                                size="default">
                                <el-option
                                    v-for="item in tyre_specifications"
                                    :key="item.id"
                                    :label="item.specifiction_name"
                                    :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col">
                    <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Models</p>
                    <div class="flex flex-row items-start justify-start w-full">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" multiple v-model="filters.tyre_model_id"
                                placeholder="Select tyre model"
                                size="default">
                                <el-option
                                    v-for="item in tyre_models"
                                    :key="item.id"
                                    :label="item.model_name"
                                    :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col">
                    <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Vendor</p>
                    <div class="flex flex-row items-start justify-start w-full">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" multiple v-model="filters.tyre_vendor_id"
                                placeholder="Select tyre vendor"
                                size="default">
                                <el-option
                                    v-for="item in tyre_vendors"
                                    :key="item.id"
                                    :label="item.vendor_name"
                                    :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col">
                    <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Tread Pattern</p>
                    <div class="flex flex-row items-start justify-start w-full">
                        <div class="flex flex-row rounded-md w-full">
                            <el-select
                                style="width: 100%" multiple v-model="filters.tyre_tread_pattern_id"
                                placeholder="Select tread pattern"
                                size="default">
                                <el-option
                                    v-for="item in tyre_tread_patterns"
                                    :key="item.id"
                                    :label="item.tread_pattern_name"
                                    :value="item.id"
                                />
                            </el-select>
                        </div>
                    </div>
                </div>

            </div>

            <div
                class="flex lg:flex-row flex-col justify-between md:items-end md:space-y-0 space-y-2 md:space-x-4 space-x-0">
                <!--        Export and Import-->
                <div class="flex flex-row mt-8">
                    <el-button-group>
                        <el-button @click="exportTyres" type="success" plain>
                            <span class="mr-3">Export</span>
                            <el-icon>
                                <Download/>
                            </el-icon>
                        </el-button>
                        <el-button @click="uploadTyreDialogVisible=!uploadTyreDialogVisible" type="warning"
                                   size="default"
                                   plain>
                            <span class="mr-3">Import</span>
                            <el-icon>
                                <Upload/>
                            </el-icon>
                        </el-button>
                    </el-button-group>
                </div>

                <!--      search-->
                <form>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="w-5 h-5 mr-3">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <input v-model="search"
                               class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                               placeholder="Search Tyres ...">
                    </div>
                </form>
            </div>
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Import dialog-->
            <el-dialog
                v-model="uploadTyreDialogVisible"
                title="Upload Tyres"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-row items-center w-full rounded mb-4">
                        <a class="py-2 px-6 text-sky-600"
                           href="/templates/tyres_template.xlsx" download="">
                            <font-awesome-icon class="mr-2 text-sm"
                                               :icon="['fas', 'download']"/>
                            Please click here to download the tyres template.
                        </a>
                    </div>
                    <form class="px-4">
                        <div v-if="company && (company.tier === 'tier1' || company.tier === 'tier2')" class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Company</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="tyre.child_company_id"
                                        placeholder="Select company" size="default">
                                        <el-option
                                            v-for="item in child_companies"
                                            :key="item.id"
                                            :label="item.child.name"
                                            :value="item.child.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.web_errors.child_company_id  && this.has_errors">
                                    {{ errors.data.web_errors.child_company_id[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row border p-2 rounded-md w-full">
                                    <input
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        class="outline-none w-full" @change="companyFile" type="file" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="upload">
                            Upload
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger" @click="uploadTyreDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!-- Add/Edit dialog-->
            <el-dialog
                top="5vh"
                v-model="addTyreDialogVisible"
                title="Add/Edit Tyres"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div v-if="company && (company.tier === 'tier1' || company.tier === 'tier2')" class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Company</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.child_company_id"
                                        placeholder="Select company" size="default">
                                        <el-option
                                            v-for="item in child_companies"
                                            :key="item.id"
                                            :label="item.child.name"
                                            :value="item.child.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.web_errors.child_company_id  && this.has_errors">
                                    {{ errors.data.web_errors.child_company_id[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Serial Number
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Tyre Serial Number"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.tyre_serial_number" type="text"
                                        name="form.name"/>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_serial_number  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_serial_number[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Size
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.tyre_size_id" placeholder="Select tyre size"
                                        size="default">
                                        <el-option
                                            v-for="item in tyre_sizes"
                                            :key="item.id"
                                            :label="item.size_name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_size_id  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_size_id[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Model
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.tyre_model_id" placeholder="Select tyre model"
                                        size="default">
                                        <el-option
                                            v-for="item in tyre_models"
                                            :key="item.id"
                                            :label="item.model_name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_model_id  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_model_id[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Store</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.tyre_store_id" placeholder="Select tyre model"
                                        size="default">
                                        <el-option
                                            v-for="item in tyre_stores"
                                            :key="item.id"
                                            :label="item.store_name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_store_id  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_store_id[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Brand
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.tyre_brand_id" placeholder="Select tyre brand"
                                        size="default">
                                        <el-option
                                            v-for="item in tyre_brands"
                                            :key="item.id"
                                            :label="item.brand_name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_brand_id  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_brand_id[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Tread Pattern
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.tyre_tread_pattern_id"
                                        placeholder="Select tyre tread pattern"
                                        size="default">
                                        <el-option
                                            v-for="item in tyre_tread_patterns"
                                            :key="item.id"
                                            :label="item.tread_pattern_name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_tread_pattern_id  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_tread_pattern_id[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Specification
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.tyre_specification_id"
                                        placeholder="Select tyre specification"
                                        size="default">
                                        <el-option
                                            v-for="item in tyre_specifications"
                                            :key="item.id"
                                            :label="item.specifiction_name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_specification_id  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_specification_id[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Vendor
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.tyre_vendor_id"
                                        placeholder="Select tyre vendor"
                                        size="default">
                                        <el-option
                                            v-for="item in tyre_vendors"
                                            :key="item.id"
                                            :label="item.vendor_name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_vendor_id  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_vendor_id[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Weight
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Tyre Weight(Kgs)"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.tyre_weight" type="text"
                                        name="form.name"/>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_weight  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_weight[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Width
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Tyre Width(mms)"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.tyre_width" type="text"
                                        name="form.name"/>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_width  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_width[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Depth
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Tyre Depth(mms)"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.tread_depth" type="text"
                                        name="form.name"/>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tread_depth  && this.has_errors">
                                        {{ errors.data.web_errors.tread_depth[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Purchase Date
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-date-picker style="width: 100%"
                                                    v-model="form.tyre_purchase_date"
                                                    type="date"
                                                    placeholder="Pick a day"
                                                    size="default"
                                    />
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_purchase_date  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_purchase_date[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Warranty Expire Date
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-date-picker style="width: 100%"
                                                    v-model="form.tyre_warranty_expire_date"
                                                    type="date"
                                                    placeholder="Pick a day"
                                                    size="default"
                                    />
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_warranty_expire_date  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_warranty_expire_date[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Warranty Period
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.tyre_warranty_period"
                                        placeholder="Select warranty period"
                                        size="default">
                                        <el-option value="1 year">1 year</el-option>
                                        <el-option value="2 years">2 years</el-option>
                                        <el-option value="3 years">3 years</el-option>
                                        <el-option value="4 years">4 years</el-option>
                                        <el-option value="5 years">5 years</el-option>
                                    </el-select>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_warranty_period  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_warranty_period[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Warranty Kms
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Tyre Warranty Kms"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.tyre_warranty_kms" type="number"
                                        name="form.name"/>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_warranty_kms  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_warranty_kms[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Manufacture Date
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-date-picker style="width: 100%"
                                                    v-model="form.manufacture_date"
                                                    type="month"
                                                    placeholder="Pick a day"
                                                    size="default"
                                    />
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.manufacture_date  && this.has_errors">
                                        {{ errors.data.web_errors.manufacture_date[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Maximum PSI</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Maximum PSI"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.max_psi" type="number"
                                        name="form.name"/>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.max_psi  && this.has_errors">
                                        {{ errors.data.web_errors.max_psi[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Recommended PSI</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Recommended PSI"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.recom_psi" type="number"
                                        name="form.name"/>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.recom_psi  && this.has_errors">
                                        {{ errors.data.web_errors.recom_psi[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">BEL Initial</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="BEL Initial"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.bel_initial" type="text"
                                        name="form.name"/>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.bel_initial  && this.has_errors">
                                        {{ errors.data.web_errors.bel_initial[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Purchase Cost VAT Inc</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Purchase Cost VAT Inc"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.purchase_cost_vat_inc" type="number"
                                        name="form.name"/>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.purchase_cost_vat_inc  && this.has_errors">
                                        {{ errors.data.web_errors.purchase_cost_vat_inc[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">DOT Number</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="DOT Number"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.dot_number" type="number"
                                        name="form.name"/>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.dot_number  && this.has_errors">
                                        {{ errors.data.web_errors.dot_number[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">PO Number</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="PO Number"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.po_number" type="number"
                                        name="form.name"/>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.po_number  && this.has_errors">
                                        {{ errors.data.web_errors.po_number[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tyre Position Type</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.tyre_position_type"
                                        placeholder="Select position type"
                                        size="default">
                                        <el-option value="AP">All Position</el-option>
                                        <el-option value="D">Drive</el-option>
                                        <el-option value="S">Steer</el-option>
                                    </el-select>
                                    <span class="text-red-500"
                                          v-if="errors.data.web_errors.tyre_position_type  && this.has_errors">
                                        {{ errors.data.web_errors.tyre_position_type[0] }}
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3"> Tyre Image (.png or .jpg)</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col border p-1 rounded-md w-full">
                                    <input
                                        accept=".png, .jpg"
                                        class="outline-none w-full" @change="tyreImage" type="file"/>
                                </div>
                            </div>
                        </div>

                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addTyre">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger" @click="openDialog(false)">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="tyres.data"  stripe size="default" style="width: 100%">
                    <el-table-column prop="company.name" label="COMPANY"/>
                    <el-table-column sortable prop="tyre_serial_number" label="SERIAL NUMBER"/>
                    <el-table-column label="STATUS">
                        <template #default="scope">
                            {{ scope.row.tyre_status === 1 ? 'Active' : 'Inactive' }}
                        </template>
                    </el-table-column>
<!--                    <el-table-column prop="tyre_status" label="STATUS"/>-->
                    <el-table-column sortable prop="tyre_warranty_period" label="WARRANTY PERIOD"/>
                    <el-table-column sortable prop="tyre_warranty_kms" label="WARRANTY KMS"/>
                    <el-table-column sortable prop="tyre_warranty_expire_date" label="WARRANTY EXPIRY DATE"/>
                    <el-table-column sortable prop="tyre_purchase_date" label="PURCHASE DATE"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="default">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="viewTyre(scope.row)">VIEW</el-dropdown-item>
                                        <el-dropdown-item @click.native="updateTyre(scope.row)">EDIT</el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteTyre(scope.row)">DELETE
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="viewTyreHistory(scope.row)">VIEW TYRE HISTORY
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="tyres.current_page"
                    :page-size="tyres.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="tyres.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>

            <!--View dialog-->
            <el-dialog
                v-model="viewTyreDialog"
                title="View Tyre"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2 w-full">
                    <div class="flex flex-row justify-center items-center">
                        <img class="h-32 w-32" v-if="view_tyre.tyre_image" :src="view_tyre.tyre_image" alt="image">
                        <img class="h-32 w-32" v-else src="../../assets/img/tyre-placeholder.png" alt="image">
                    </div>
                    <div class="flex flex-col w-full space-y-2">
                        <div class="flex items-center py-4 border border-b-0 justify-center">
                            <span class="text-xl font-medium">Tyre Details</span>
                        </div>
                        <div class="flex flex-row w-full bg-white">
                            <div class="py-4 flex flex-col h-full w-1/2 mr-5 border">
                                <table class="w-full text-sm text-left text-gray-500 table-auto">
                                    <tbody>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Status
                                        </th>
                                        <td class="px-6 py-4">
                                            <el-tag effect="dark"
                                                    :type="view_tyre.status === 0 ? 'danger' : 'success'">
                                                {{ view_tyre.status === 0 ? 'Inactive' : 'Active' }}
                                            </el-tag>
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Tyre Serial Number
                                        </th>
                                        <td class="flex flex-row px-6 py-4 items-center">
                                            {{ view_tyre.tyre_serial_number }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Tyre Store
                                        </th>
                                        <td class="flex flex-row px-6 py-4 items-center">
                                            {{ view_tyre.tyre_store ? view_tyre.tyre_store.store_name : '' }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Tyre Brand
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.tyre_brand ? view_tyre.tyre_brand.brand_name : '' }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Tyre Model
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.tyre_model ? view_tyre.tyre_model.model_name : '' }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            DOT Number
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.dot_number }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Po Number
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.po_number }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Date Created
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.created_at }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Last Updated
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.updated_at }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Manufacture Date
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.manufacture_date }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="py-4 flex flex-col h-full w-1/2 border">
                                <table class="w-full text-sm text-left text-gray-500 table-auto">
                                    <tbody>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Tyre Vendor
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.tyre_vendor ? view_tyre.tyre_vendor.vendor_name : '' }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Tyre Specification
                                        </th>
                                        <td class="flex flex-row px-6 py-4 items-center">
                                            {{
                                                view_tyre.tyre_specification ? view_tyre.tyre_specification.specifiction_name : ''
                                            }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Tyre Size
                                        </th>
                                        <td class="flex flex-row px-6 py-4 items-center">
                                            {{ view_tyre.tyre_size ? view_tyre.tyre_size.size_name : '' }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Tyre Deploy Status
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.is_deploy === 0 ? 'Not Deployed' : 'Deployed' }}
                                        </td>
                                    </tr>
                                    <tr v-if="view_tyre.is_deploy === 1" class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Tyre Deployed On
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.vehicle ? view_tyre.vehicle.reg_number : '' }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Tyre Position Type
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.tyre_position_type }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Tyre Position
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.tyre_position }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Tyre Axel Id
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.tyre_axel_id }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            BEL Initial
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.bel_initial }}
                                        </td>
                                    </tr>
                                    <tr class="bg-white">
                                        <th scope="row" class="px-6 py-5 font-medium text-gray-900 whitespace-nowrap">
                                            Purchase Cost Vat Inc
                                        </th>
                                        <td class="px-6 py-4">
                                            {{ view_tyre.purchase_cost_vat_inc }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </el-dialog>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown, Download, Upload} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import company from '@/mixins/company';

export default {
    mixins: [company],
    data() {
        return {
            search: '',
            viewTyreDialog: false,
            uploadTyreDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            tyre: {},
            filters: {
                paginate: true,
                per_page: 10
            },
            user: {},
            view_tyre: {}
        };
    },
    components: {
        ArrowDown,
        Download,
        Upload

    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getTyreStores({})
        this.getTyreSizes({})
        this.getTyreSpecifications({})
        this.getTyreBrands({})
        this.getTyreAxels({})
        this.getTyreModels({})
        this.getTyreTreadPatterns({})
        this.getTyreVendors({})
        this.getVehicles({})

        this.getTyresList({
            ...this.filters,
            tyre_store_id: this.$route.query.store_id ? parseInt(this.$route.query.store_id) : null,
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id
        })
    },
    computed: {
        ...mapGetters({
            errors: 'tyres/errors',
            has_errors: 'tyres/has_errors',
            tyres: 'tyres/tyres',
            vehicles: 'vehicles/vehicles',
            tyre_stores: 'tyre_stores/tyre_stores',
            tyre_sizes: 'tyre_sizes/tyre_sizes',
            tyre_models: 'tyre_models/tyre_models',
            tyre_specifications: 'tyre_specifications/tyre_specifications',
            tyre_vendors: 'tyre_vendors/tyre_vendors',
            tyre_axels: 'tyre_axels/tyre_axels',
            tyre_brands: 'tyre_brands/tyre_brands',
            tyre_tread_patterns: 'tyre_tread_patterns/tyre_tread_patterns',
            addTyreDialogVisible: 'tyres/addTyreDialogVisible'
        })
    },
    watch: {
        filters: {
            deep: true,
            immediate: false,
            handler: function () {
                this.getTyresList({
                    ...this.filters,
                    id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id
                })
            }
        },
        search: function (val) {
            this.getTyresList({
                ...this.filters,
                search: val,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id
            });
        }
    },
    methods: {
        ...mapActions({
            getTyresList: 'tyres/getTyres',
            uploadTyres: 'tyres/uploadTyres',
            downloadTyres: 'tyres/exportTyres',
            createTyres: 'tyres/createTyres',
            editTyre: 'tyres/editTyre',
            getCompanies: 'companies/getCompanies',
            getTyreStores: 'tyre_stores/getTyreStores',
            getTyreSizes: 'tyre_sizes/getTyreSizes',
            getTyreModels: 'tyre_models/getTyreModels',
            getTyreVendors: 'tyre_vendors/getTyreVendors',
            getTyreSpecifications: 'tyre_specifications/getTyreSpecifications',
            getTyreBrands: 'tyre_brands/getTyreBrands',
            getTyreAxels: 'tyre_axels/getTyreAxels',
            getTyreTreadPatterns: 'tyre_tread_patterns/getTyreTreadPatterns',
            getVehicles: 'vehicles/getVehicles',
            removeTyre: 'tyres/deleteTyre',
            openAddTyreDialogVisible: 'tyres/openAddTyreDialogVisible'
        }),
        openDialog(status) {
            this.openAddTyreDialogVisible(status);
        },
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getTyresList({
                paginate: true, ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id
            });
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getTyresList({
                paginate: true, ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id
            });
        },
        addTyre() {
            this.form.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id;
            if (this.form.id) {
                this.form.tyre_purchase_date = new Date(this.form.tyre_purchase_date).toLocaleString()
                this.form.tyre_warranty_expire_date = new Date(this.form.tyre_warranty_expire_date).toLocaleString()
                this.editTyre({...this.form, admin: true}).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.openAddTyreDialogVisible(false)
                    }
                })
            } else {
                this.form.tyre_image = this.tyre_image
                this.form.tyre_purchase_date = new Date(this.form.tyre_purchase_date).toLocaleString()
                this.form.tyre_warranty_expire_date = new Date(this.form.tyre_warranty_expire_date).toLocaleString()
                this.createTyres({...this.form, admin: true}).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.openAddTyreDialogVisible(false)
                    }
                });
            }
        },
        updateTyre(item) {
            this.openAddTyreDialogVisible(true)
            this.form = Object.assign({}, this.form, item);
        },
        deleteTyre(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.removeTyre({
                    id: item.id,
                    company_id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id
                })
            }
        },
        upload() {
            this.tyre.file = this.file;
            this.tyre.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id;
            this.uploadTyres(this.tyre)
                .then(() => {
                    this.getTyresList({
                        ...this.filters,
                        id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id
                    })
                    this.uploadTyreDialogVisible = false
                })
                .catch((e) => {
                    this.$message.error(e.response.data.message)
                })
        },
        companyFile(e) {
            this.file = e.target.files[0]
        },
        tyreImage(e) {
            this.tyre_image = e.target.files[0]
        },
        exportTyres() {
            this.downloadTyres({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company?.company_id
            })
        },
        viewTyre(tyre) {
            this.viewTyreDialog = true
            this.view_tyre = Object.assign({}, this.view_tyre, tyre);
        },
        viewTyreHistory(item) {
            this.$router.push('/tyres/' + item.id)
        }

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
