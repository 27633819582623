<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        CompanyConfigs
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addCompanyConfigDialogVisible = true">Add Config
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div class="flex lg:flex-row flex-col justify-between md:items-start md:space-y-0 space-y-2 md:space-x-4 space-x-0 py-8 px-6 border-b">
            <div class="flex flex-row justify-start items-end mb-3 space-x-4">
                <!--        Export and Import-->
<!--                <div class="flex flex-row">-->
<!--                    <el-button-group>-->
<!--                        <el-button @click="exportCompanyConfigs" type="success" plain>-->
<!--                            <span class="mr-3">Export</span>-->
<!--                            <el-icon>-->
<!--                                <Download/>-->
<!--                            </el-icon>-->
<!--                        </el-button>-->
<!--                        <el-button @click="uploadCompanyConfigDialogVisible=!uploadCompanyConfigDialogVisible"-->
<!--                                   type="warning"-->
<!--                                   size="default"-->
<!--                                   plain>-->
<!--                            <span class="mr-3">Import</span>-->
<!--                            <el-icon>-->
<!--                                <Upload/>-->
<!--                            </el-icon>-->
<!--                        </el-button>-->
<!--                    </el-button-group>-->
<!--                </div>-->
            </div>

            <form>
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 mr-3">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                        </svg>
                    </div>
                    <input v-model="search"
                           class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                           placeholder="Search Routes ...">
                </div>
            </form>
        </div>

        <div class="block w-full overflow-x-auto">

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addCompanyConfigDialogVisible"
                title="Add/Edit CompanyConfigs"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div v-if="company && (company.tier === 'tier1' || company.tier === 'tier2')" class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Company</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.child_company_id"
                                        placeholder="Select company" size="default">
                                        <el-option
                                            v-for="item in child_companies"
                                            :key="item.id"
                                            :label="item.child.name"
                                            :value="item.child.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.child_company_id  && this.has_errors">
                                    {{ errors.data.errors.child_company_id[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Working Hours Start</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-time-picker
                                        format="HH:mm"
                                        arrow-control
                                        style="width: 100%"
                                        v-model="form.working_hours_start"
                                        placeholder="Pick the time"
                                        size="default"
                                    />
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.working_hours_start  && this.has_errors">
                                    {{ errors.data.errors.working_hours_start[0] }}
                                </span>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Working Hours End</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-time-picker
                                        format="HH:mm"
                                        arrow-control
                                        style="width: 100%"
                                        v-model="form.working_hours_end"
                                        placeholder="Pick the time"
                                        size="default"
                                    />
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.working_hours_end  && this.has_errors">
                                    {{ errors.data.errors.working_hours_end[0] }}
                                </span>
                        </div>

                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Journeys Prefix<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.journeys_prefix" type="text"
                                        name="form.name"/>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.journeys_prefix  && this.has_errors">
                                    {{ errors.data.errors.journeys_prefix[0] }}
                                </span>
                        </div>

                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addCompanyConfig">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="addCompanyConfigDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="company_configs.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="company.name" label="COMPANY"/>
                    <el-table-column prop="working_hours_start_time" label="WORKING HOURS START"/>
                    <el-table-column prop="working_hours_end_time" label="WORKING HOURS END"/>
                    <el-table-column prop="journeys_prefix" label="JOURNEYS PREFIX"/>
                    <el-table-column prop="lateness_tolerance" label="LATENESS TOLERANCE"/>
                    <el-table-column prop="created_at" label="CREATED AT"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateCompanyConfig(scope.row)">EDIT
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteCompanyConfig(scope.row)">DELETE
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="company_configs.current_page"
                    :page-size="company_configs.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="company_configs.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import company from "@/mixins/company";

export default {
    mixins: [company],
    data() {
        return {
            search: '',
            uploadCompanyConfigDialogVisible: false,
            addCompanyConfigDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            company_route: {},
            user: {},
            filters: {
                paginate: true,
                per_page: 10
            },
        };
    },
    components: {
        ArrowDown

    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getCompanyConfigsList({
            ...this.filters,
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
    },
    computed: {
        ...mapGetters({
            company_configs: 'configs/configs',
            routes: 'routes/routes',
            companies: 'companies/companies',
            errors:'configs/errors',
            has_errors:'configs/has_errors'
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getCompanyConfigsList(this.filters)
            }
        },
        search: function (val) {
            this.getCompanyConfigsList({
                ...this.filters,
                search: val
            });
        }
    },
    methods: {
        ...mapActions({
            getCompanyConfigsList: 'configs/getConfigs',
            createCompanyConfigs: 'configs/createConfigs',
            editCompanyConfig: 'configs/editConfig',
            removeCompanyConfig: 'configs/deleteConfig',
            getCompanies: 'companies/getCompanies'
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getCompanyConfigsList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getCompanyConfigsList({paginate: true, ...this.filters});
        },
        addCompanyConfig() {
            this.form.company_id = this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id;
            if (this.form.id) {
                this.editCompanyConfig(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addCompanyConfigDialogVisible = false
                    }
                })
            } else {
                this.createCompanyConfigs(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addCompanyConfigDialogVisible = false
                    }
                });
            }
        },
        updateCompanyConfig(item) {
            this.addCompanyConfigDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        deleteCompanyConfig(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.removeCompanyConfig({
                    id: item.id
                }).then(() => {
                    this.getCompanyConfigsList({
                        ...this.filters,
                        id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                    })
                })
            }
        },

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
