<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div
                    class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Companies
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addCompanyDialogVisible = true">Add Company
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div class="flex flex-row justify-between py-5 text-gray-100 border-b px-4">

            <div class="flex flex-row px-4 space-x-4">
                <!--        Export and Import-->
                <div>
                    <el-button-group>
                        <el-button @click="uploadDialogVisible=!uploadDialogVisible" type="warning" size="default"
                                   plain>
                            <span class="mr-3">Import</span>
                            <el-icon>
                                <Upload/>
                            </el-icon>
                        </el-button>
                        <el-button @click="exportCompanies" type="success" plain>
                            <span class="mr-3">Export</span>
                            <el-icon>
                                <Download/>
                            </el-icon>
                        </el-button>
                    </el-button-group>
                </div>
            </div>

            <!--      search-->
            <form>
                <div class="relative text-black">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 mr-3">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                        </svg>
                    </div>
                    <input v-model="search"
                           class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                           placeholder="Search Companies ...">
                </div>
            </form>
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Import dialog-->
            <el-dialog
                v-model="uploadDialogVisible"
                title="Upload Companies"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-row items-center w-full rounded mb-4">
                        <a class="py-2 px-6 text-sky-600"
                           href="/templates/companies_template.csv" download="">
                            <font-awesome-icon class="mr-2 text-sm"
                                               :icon="['fas', 'download']"/>
                            Please click here to download the companies template.
                        </a>
                    </div>
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">File (.xls or .xlsx or .csv)<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row border p-2 rounded-md w-full">
                                    <input
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        class="outline-none w-full" @change="companyFile" type="file" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="upload">
                            Upload
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger" @click="uploadDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="addCompanyDialogVisible"
                title="Add/Edit Companies"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Logo</p>
                            <el-upload
                                class="avatar-uploader mb-3 hover:border-secondary"
                                :show-file-list="false"
                                :before-upload="beforeAvatarUpload"
                                :on-change="handleFileChange">
                                <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="Avatar">
                                <el-icon v-else class="avatar-uploader-icon">
                                    <Plus/>
                                </el-icon>
                            </el-upload>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Name<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.name" type="text"
                                        name="form.name" required/>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.name && this.has_errors">
                                    {{ errors.data.errors.name[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Physical Address</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input
                                        class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.physicalAddress" type="text"
                                        name="form.name"/>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.physicalAddress && this.has_errors">
                                    {{ errors.data.errors.physicalAddress[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Phone</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input placeholder="+254712345678"
                                           class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                           v-model="form.phone" type="text"
                                           name="form.name"/>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.phone && this.has_errors">
                                    {{ errors.data.errors.phone[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Email</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input type="email"
                                           class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                           v-model="form.email"
                                           name="form.name"/>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.email && this.has_errors">
                                    {{ errors.data.errors.email[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Company Registration Number</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input placeholder="registration"
                                           class="py-2 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                           v-model="form.company_reg_number" type="text"
                                           name="form.name"/>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.company_reg_number && this.has_errors">
                                    {{ errors.data.errors.company_reg_number[0] }}
                                </span>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Company Tier</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.tier"
                                        placeholder="Select company tier"
                                        size="default">
                                        <el-option value="tier1">Tier 1</el-option>
                                        <el-option value="tier2">Tier 2</el-option>
                                        <el-option value="tier3">Tier 3</el-option>
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.tier && this.has_errors">
                                    {{ errors.data.errors.tier[0] }}
                                </span>
                        </div>
                        <div v-if="form.tier === 'tier2'" class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Type</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.type"
                                        placeholder="Select tier 2 company type"
                                        size="default">
                                        <el-option value="agent">Agent</el-option>
                                        <el-option value="directly_managed">Directly Managed</el-option>
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.type && this.has_errors">
                                    {{ errors.data.errors.type[0] }}
                                </span>
                        </div>
                        <div v-if="form.tier === 'tier2'" class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Parent Company</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.parent_id"
                                        placeholder="Select parent company"
                                        size="default">
                                        <el-option
                                            v-for="item in tier1_companies.data"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.parent_id && this.has_errors">
                                    {{ errors.data.errors.parent_id[0] }}
                                </span>
                        </div>
                        <div v-if="form.tier === 'tier3'" class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Parent Company</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.parent_id"
                                        placeholder="Select parent company"
                                        size="default">
                                        <el-option
                                            v-for="item in tier2_companies.data"
                                            :key="item.id"
                                            :label="item.name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <span class="text-red-500" v-if="errors.data.errors.parent_id && this.has_errors">
                                    {{ errors.data.errors.parent_id[0] }}
                                </span>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addCompany">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger" @click="addCompanyDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>


            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="companies.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="name" label="NAME"/>
                    <el-table-column prop="physicalAddress" label="PHYSICAL ADDRESS"/>
                    <el-table-column prop="phone" label="PHONE"/>
                    <el-table-column prop="email" label="EMAIL"/>
                    <el-table-column prop="company_reg_number" label="REGISTRATION NO"/>
                    <el-table-column prop="created_at" label="CREATED AT"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="viewCompanyTyres(scope.row)">View Tyres
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="viewCompanyVehicles(scope.row)">View Vehicles
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="viewCompanyRoutes(scope.row)">View Routes
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="viewCompanyCommodities(scope.row)">View
                                            Commodities
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="viewCompanyBranches(scope.row)">View
                                            Branches
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="viewCompanyConfigs(scope.row)">View
                                            Configs
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="viewCompanyTowns(scope.row)">View Towns
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="assignModules(scope.row)">Assign Modules
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="updateCompany(scope.row)">Edit Company
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteCompany(scope.row)">Delete Company
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <!--Pagination-->
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="companies.current_page"
                    :page-size="companies.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="companies.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>

            <!--Modules dialog-->
            <el-dialog
                v-model="assignModulesDialogVisible"
                title="Modules"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="flex flex-col">
                        <div class="modal-body">
                            <div v-for="(module, index) in modules_p" :key="index">
                                <div class="flex px-2 py-2 my-2 items-center">
                                    <el-checkbox
                                        :id="'check'+index"
                                        v-model="module.status">
                                    </el-checkbox>
                                    <label class="font-medium px-6 text-lg" :for="'check'+index">
                                        {{ module.module }}
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addModule">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="assignModulesDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown, Upload, Download, Plus} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    data() {
        return {
            search: '',
            uploadDialogVisible: false,
            addCompanyDialogVisible: false,
            windowWidth: window.innerWidth,
            assignModulesDialogVisible: false,
            form: {},
            company: {},
            imageUrl: '',
            filters: {
                paginate: true,
                per_page: 10
            },
            logo: null
        };
    },
    components: {
        Plus,
        ArrowDown, Upload, Download

    },
    mounted() {
        this.getCompaniesList(this.filters)
        this.getTier1Companies(this.filters)
        this.getTier2Companies(this.filters)
    },
    computed: {
        modules_p() {
            return this.modules.data.map(function (item) {
                item.status = item.status == 1 ? true : false;
                return item;
            });
        },
        ...mapGetters({
            companies: 'companies/companies',
            modules: 'modules/modules',
            has_errors:'companies/has_errors',
            errors:'companies/errors',
            tier1_companies:'companies/tier1_companies',
            tier2_companies:'companies/tier2_companies',
        })
    },
    watch: {
        search: function (val) {
            this.getCompaniesList({
                ...this.filters,
                search: val
            });
        }
    },
    methods: {
        ...mapActions({
            getCompaniesList: 'companies/getCompanies',
            uploadCompanies: 'companies/uploadCompanies',
            downloadCompanies: 'companies/exportCompanies',
            createCompanies: 'companies/createCompanies',
            editCompany: 'companies/editCompany',
            removeCompany: 'companies/deleteCompany',
            getModules: 'modules/getModules',
            saveCompanyModules: 'modules/saveCompanyModules',
            getTier1Companies:'companies/getTier1Companies',
            getTier2Companies:'companies/getTier2Companies',
        }),
        handleFileChange(e) {
            this.logo = e.raw
            this.imageUrl = URL.createObjectURL(e.raw);
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('Avatar picture size can not exceed 2MB!');
            }
            return isLt2M;
        },
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getCompaniesList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getCompaniesList({paginate: true, ...this.filters});
        },
        addCompany() {
            if (this.form.id) {
                this.editCompany({...this.form, logo: this.logo}).then(() => {
                    if (!this.has_errors) {
                        this.form = {};
                        this.addCompanyDialogVisible = false;
                        this.imageUrl = ''
                    }
                });
            } else {
                this.createCompanies({...this.form, logo: this.logo}).then(() => {
                    if (!this.has_errors) {
                        this.form = {};
                        this.addCompanyDialogVisible = false;
                        this.imageUrl = ''
                    }
                });
            }
        },
        updateCompany(item) {
            this.addCompanyDialogVisible = true
            this.form = Object.assign({}, this.form, item);
            this.imageUrl = this.form.logo
            this.form.parent_id = item.parent_company.parent_id
        },
        deleteCompany(item) {
            let conf = confirm(`Are yous sure you want to remove this company?`)
            if (conf) {
                this.removeCompany({
                    id: item.id
                })
            }
        },
        viewCompanyBranches(item){
            this.$router.push(`/admin/branches/${item.id}`)
        },
        viewCompanyTowns(item) {
            this.$router.push(`/admin/towns/${item.id}`)
        },
        viewCompanyVehicles(item) {
            this.$router.push(`/admin/vehicles/${item.id}`)
        },
        viewCompanyRoutes(item) {
            this.$router.push(`/admin/routes/${item.id}`)
        },
        viewCompanyCommodities(item) {
            this.$router.push(`/admin/commodities/${item.id}`)
        },
        viewCompanyTyres(item) {
            this.$router.push(`/admin/tyres/${item.id}`)
        },
        viewCompanyConfigs(item){
            this.$router.push(`/admin/configs/${item.id}`)
        },
        upload() {
            this.uploadCompanies({
                file: this.file,
            }).then(() => {
                this.getCompaniesList(this.filters)
                this.uploadDialogVisible = false
                this.$message.success('Companies uploaded successfully!')
            }).catch((e) => {
                this.$message.error(e.response.data.message)
            })
        },
        companyFile(e) {
            this.file = e.target.files[0]
        },
        exportCompanies() {
            this.downloadCompanies({...this.filters})
        },
        assignModules(item) {
            this.company = item
            this.getModules({id: item.id}).then(() => {
                this.assignModulesDialogVisible = !this.assignModulesDialogVisible
            })
        },
        addModule() {
            this.saveCompanyModules({
                ...this.company,
                modules: this.modules_p
            }).then(() => {
                this.assignModulesDialogVisible = !this.assignModulesDialogVisible
            })
        }

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}

.avatar-uploader {
    display: block;
    width: 178px;
    height: 178px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
