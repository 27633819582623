<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Inspections
                    </h3>
                    <div class="flex flex-row justify-end">
                        <button class="bg-secondary text-white px-10 py-2 font-semibold rounded-md"
                                @click="addNewInspection">Add New Inspection
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div class="flex flex-col py-5 text-gray-100 border-b px-4">

            <form>
                <div class="relative w-1/3">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 mr-3">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                        </svg>
                    </div>
                    <input v-model="search"
                           class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                           placeholder="Search Inspections ...">
                </div>
            </form>
        </div>

        <div class="block w-full overflow-x-auto">
            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="inspections.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="company.name" label="COMPANY"/>
                    <el-table-column prop="title" label="TITLE"/>
                    <el-table-column sortable prop="inspection_questions_count" label="QUESTIONS"/>
                    <el-table-column sortable prop="vehicles_count" label="NO. OF VEHICLES"/>
                    <el-table-column sortable prop="created_at" label="CREATED AT"/>
                    <el-table-column prop="created_by.first_name" label="CREATED BY"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-dropdown size="large">
                                <el-button>
                                    Actions
                                    <el-icon class="el-icon--right">
                                        <arrow-down/>
                                    </el-icon>
                                </el-button>
                                <template #dropdown>
                                    <el-dropdown-menu>
                                        <el-dropdown-item @click.native="updateInspection(scope.row)">EDIT
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="duplicateInspection(scope.row)">DUPLICATE
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="deleteInspection(scope.row)">DELETE
                                        </el-dropdown-item>
                                        <el-dropdown-item @click.native="viewVehicles(scope.row)">ASSIGNED VEHICLES
                                        </el-dropdown-item>
                                    </el-dropdown-menu>
                                </template>
                            </el-dropdown>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="inspections.current_page"
                    :page-size="inspections.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="inspections.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>

            <!-- Duplicate dialog-->
            <el-dialog
                v-model="duplicateInspectionDialogVisible"
                title="Duplicate inspection"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Title<span
                                class="text-red-500 ml-2">*</span></p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <input v-model="duplicate.title"
                                           class="outline-none w-full" type="text" required/>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="saveDuplicateInspection">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="duplicateInspectionDialogVisible = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!--Vehicles dialog-->
            <el-dialog
                v-model="assignInspectionDialog"
                title="Assign Inspections"
                :width="windowWidth > 768 ? '40%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <!--Table-->
                    <div class="border p-5 rounded shadow">
                        <div class="py-8 px-4">
                            <el-table class="" :data="vehicles.data" stripe size="large" style="width: 100%">
                                <el-table-column prop="vehicle.reg_number" label="VEHICLE"/>
                                <el-table-column prop="created_at" label="CREATED AT"/>
                                <el-table-column label="Operations">
                                    <template #default="scope">
                                        <el-button
                                            size="small"
                                            type="danger"
                                            plain
                                            @click="handleDelete(scope.row)">Delete
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="flex flex-row p-5 w-full justify-end">
                            <el-pagination
                                :current-page="vehicles.current_page"
                                :page-size="vehicles.per_page"
                                :page-sizes="[5,10,20,50]"
                                :small=false
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="vehicles.total"
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                            />
                        </div>
                    </div>

                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Vehicles</p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-row rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="vehicle_inspection.vehicle_id"
                                        placeholder="Select vehicle" size="default">
                                        <el-option
                                            v-for="item in unassigned_vehicles"
                                            :key="item.id"
                                            :label="item.reg_number"
                                            :value="item.id"
                                        />
                                    </el-select>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="saveVehicleInspection">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="assignInspectionDialog = false">
                            Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {ArrowDown} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

export default {
    data() {
        return {
            search: '',
            duplicateInspectionDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            duplicate: {},
            inspection: {},
            filters: {
                paginate: true,
                per_page: 10
            },
            assignInspectionDialog: false,
            vehicle_inspection: {}
        };
    },
    components: {
        ArrowDown

    },
    mounted() {
        this.getInspectionsList(this.filters)
    },
    computed: {
        ...mapGetters({
            inspections: 'inspections/inspections',
            vehicles: 'inspections/vehicles',
            unassigned_vehicles: 'inspections/unassigned_vehicles'
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getInspectionsList(this.filters)
            }
        },
        search: function (val) {
            this.getInspectionsList({
                ...this.filters,
                search: val
            });
        }
    },
    methods: {
        ...mapActions({
            getInspectionsList: 'inspections/getInspections',
            createInspections: 'inspections/createInspections',
            editInspection: 'inspections/editInspection',
            removeInspection: 'inspections/deleteInspection',
            getInspectionVehicles: 'inspections/getInspectionVehicles',
            getUnassignedVehicles: 'inspections/getUnassignedVehicles',
            createVehicleInspections: 'vehicle_inspections/createVehicleInspections',
            deleteVehicleInspection: 'vehicle_inspections/deleteVehicleInspection'
        }),
        addNewInspection() {
            this.$router.push('question-create')
        },
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getInspectionsList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getInspectionsList({paginate: true, ...this.filters});
        },
        addInspection() {
            if (this.form.id) {
                this.editInspection(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addInspectionDialogVisible = false
                    }
                })
            } else {
                this.createInspections(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addInspectionDialogVisible = false
                    }
                });
            }
        },
        updateInspection(item) {
            this.$router.push(`/question-edit/${item.id}`)
        },
        deleteInspection(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.removeInspection({
                    id: item.id
                })
            }
        },
        duplicateInspection(item) {
            this.duplicateInspectionDialogVisible = true
            this.duplicate = Object.assign({}, this.duplicate, item);
        },
        viewVehicles(item) {
            this.vehicle_inspection.inspection_id = item.id
            this.assignInspectionDialog = true
            this.getInspectionVehicles({inspection_id: item.id});
            this.getUnassignedVehicles({inspection_id: item.id})
        },
        saveDuplicateInspection() {
            this.duplicate.duplicate_id = this.duplicate.id
            this.editInspection(this.duplicate).then(() => {
                if (!this.has_errors) {
                    this.duplicate = {}
                    this.duplicateInspectionDialogVisible = false
                }
            });
        },
        saveVehicleInspection() {
            this.createVehicleInspections(this.vehicle_inspection).then(() => {
                this.getInspectionVehicles({inspection_id: this.vehicle_inspection.inspection_id})
                this.getUnassignedVehicles({inspection_id: this.vehicle_inspection.inspection_id})
            });
        },
        handleDelete(item) {
            this.deleteVehicleInspection({id: item.id}).then(() => {
                this.getInspectionVehicles({inspection_id: item.inspection_id});
                this.getUnassignedVehicles({inspection_id: item.inspection_id})
            });
        }
    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
