<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div class="relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Defects
                    </h3>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div class="flex lg:flex-row flex-col justify-between md:items-start md:space-y-0 space-y-2 md:space-x-4 space-x-0 py-8 px-6 border-b">
            <div class="block">
                <span class="text-black mr-2">Date Range</span>
                <el-date-picker
                    v-model="date_range"
                    type="datetimerange"
                    :shortcuts="shortcuts"
                    range-separator="To"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                />
            </div>

            <div class="flex flex-row justify-start items-end mb-3 space-x-4">
                <!--        Export and Import-->
                <div class="flex flex-row">
                    <el-button @click="exportDefects" type="success" plain>
                        <span class="mr-3">Export as Excel</span>
                        <el-icon>
                            <Download/>
                        </el-icon>
                    </el-button>
                </div>
            </div>

            <form>
                <div class="relative">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                             stroke="currentColor" class="w-5 h-5 mr-3">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                        </svg>
                    </div>
                    <input v-model="search"
                           class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                           placeholder="Search Defects ...">
                </div>
            </form>
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="defects.data" stripe size="large" style="width: 100%">
                    <el-table-column sortable prop="inspection.company.name" label="COMPANY"/>
                    <el-table-column sortable prop="vehicle.reg_number" label="VEHICLE REG NO."/>
                    <el-table-column sortable prop="created_at" label="DATE CREATED"/>
                    <el-table-column sortable prop="date_in_progress" label="DATE IN PROGRESS"/>
                    <el-table-column sortable prop="date_corrected" label="DATE CORRECTED"/>

                    <el-table-column label="STATUS">
                        <template #default="scope">
                            <el-tag effect="dark" :type="scope.row.status === 'new' ? 'warning' : scope.row.status === 'in progress' ? '' : 'success'">
                                {{ scope.row.status}}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column label="DEFECT">
                        <template #default="scope">
                            {{scope.row.inspection_question_option ? scope.row.inspection_question_option.option : scope.row.inspection_question ? scope.row.inspection_question.title : scope.row.inspection.title}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="user.first_name" label="USER"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-button size="default" @click="viewDefectDetail(scope.row)"  circle>
                                <el-icon>
                                    <DArrowRight/>
                                </el-icon>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="defects.current_page"
                    :page-size="defects.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="defects.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {Download, DArrowRight} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import moment from "moment/moment";

export default {
    data() {
        return {
            search: '',
            uploadDefectDialogVisible: false,
            addDefectDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            inspection_log: {},
            filters: {
                paginate: true,
                per_page: 10
            },
            activeName: 'all',
            date_range:[],
            shortcuts:[
                {
                    text: 'Today',
                    value: new Date(),
                },
                {
                    text: 'Yesterday',
                    value: () => {
                        const date = new Date()
                        date.setTime(date.getTime() - 3600 * 1000 * 24)
                        return date
                    },
                },
                {
                    text: 'This week',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setHours(0, 0, 0, 0); // Start of the current day
                        const dayOfWeek = start.getDay(); // 0 (Sunday) to 6 (Saturday)
                        const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
                        start.setDate(start.getDate() - daysToSubtract);
                        return [start, end];
                    },
                },
                {
                    text: 'Last week',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]
                    },
                },
                {
                    text: 'This month',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setDate(1); // Start of the current month
                        return [start, end];
                    },
                },
                {
                    text: 'Last month',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        return [start, end]
                    },
                },
                {
                    text: 'Last 3 months',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                        return [start, end]
                    },
                },
                {
                    text: 'This year',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(0, 1); // Start of the current year
                        return [start, end];
                    },
                },
            ]
        }
    },
    components: {
        DArrowRight,
         Download

    },
    mounted() {
        this.getDefectsList(this.filters)
    },
    computed: {
        ...mapGetters({
            defects: 'defects/defects',
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getDefectsList(this.filters)
            }
        },
        'date_range': function (val){
            this.filters.start = moment(val[0]).format('DD-MM-YYYY HH:MM')
            this.filters.end = moment(val[1]).format('DD-MM-YYYY HH:MM')
            this.getDefectsList(this.filters)
        },
        search: function (val) {
            this.getDefectsList({
                ...this.filters,
                search: val
            });
        }
    },
    methods: {
        ...mapActions({
            getDefectsList: 'defects/getDefects',
            downloadDefects: 'defects/exportDefects'
        }),
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getDefectsList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getDefectsList({paginate: true, ...this.filters});
        },
        exportDefects() {
            this.downloadDefects({...this.filters})
        },
        viewDefectDetail(item) {
            this.$router.push('/defect-details/' + item.id)
        }

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
