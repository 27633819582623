import api from "../api";
import {ElMessage} from 'element-plus'

export default {
    namespaced: true,
    state: {
        companies: {data: [], current_page: 1, total: 0, per_page: 10},
        tier1_companies: {data: [], current_page: 1, total: 0, per_page: 10},
        tier2_companies: {data: [], current_page: 1, total: 0, per_page: 10},
        child_companies: {data: [], current_page: 1, total: 0, per_page: 10},
        errors: {
            data: {
                errors: {
                    name: [],
                    physicalAddress: [],
                    email: [],
                    phone: [],
                    company_reg_number: []
                }
            }
        },
        has_errors: false,
        company:[]
    },
    getters: {
        companies: state => state.companies,
        tier1_companies: state => state.tier1_companies,
        tier2_companies: state => state.tier2_companies,
        child_companies: state => state.child_companies,
        errors: state => state.errors,
        has_errors: state => state.has_errors,
        company: state => state.company,
    },
    mutations: {
        setCompanies(state, data) {
            state.companies = data;
        },
        setTier1Companies(state, data) {
            state.tier1_companies = data;
        },
        setTier2Companies(state, data) {
            state.tier2_companies = data;
        },
        setChildCompanies(state, data) {
            state.child_companies = data;
        },
        setErrors(state, errors) {
            state.errors = errors
        },
        hasErrors(state, val) {
            state.has_errors = val
            if (!val) {
                state.errors = {
                    data: {
                        errors: {
                            name: [],
                            physicalAddress: [],
                            email: [],
                            phone: [],
                            company_reg_number: []
                        }
                    }
                };
            }
        },
        setCompany(state, data) {
            state.company = data;
        },
    },
    actions: {
        async getCompanies({commit}, filters) {
            try {
                const response = await api.getCompanies(filters);
                commit('setCompanies', response);
            } catch (e) {
                console.log(e)
            }
        },

        async createCompanies({commit, dispatch}, data) {
            try {
                commit('hasErrors', false)
                await api.createCompanies(data);
                dispatch('getCompanies', {
                    paginate: true
                })

                ElMessage({
                    message: 'Company created successfully!',
                    type: 'success',
                })
            } catch (e) {
                commit('hasErrors', true)
                commit('setErrors', e.response)
                ElMessage({
                    message: 'Error creating Company!',
                    type: 'error',
                })
            }
        },
        async getCompany({commit}, id) {
            try {
                const response = await api.getCompany(id);
                commit('setCompany', response.data);
            } catch (e) {
                console.log(e)
            }
        },

        async editCompany({dispatch}, data) {
            try {
                await api.editCompany(data);
                dispatch('getCompanies', {
                    paginate: true
                });
                ElMessage({
                    message: 'Company updated successfully!',
                    type: 'success',
                })
            } catch (e) {
                ElMessage({
                    message: 'Error updating Company',
                    type: 'error',
                })
            }
        },
        async deleteCompany({dispatch}, payload) {
            try {
                const response = await api.deleteCompany(payload)
                dispatch('getCompanies', {})
                if (response.status === 401) {
                    ElMessage({
                        message: response.message,
                        type: 'warning',
                    })
                } else {
                    ElMessage({
                        message: response.message,
                        type: 'success',
                    })
                }
            } catch (e) {

                ElMessage({
                    message: e.response.data.message,
                    type: 'error',
                })
            }
        },
        async uploadCompanies(context, payload) {
            return await api.uploadCompanies(payload)
        },
        async exportCompanies(context, filters) {
            try {
                const response = await api.exportCompanies(filters);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(
                    new Blob([response.data]));
                link.setAttribute('download', 'companies ' + Date.now() + '.xlsx');
                document.body.appendChild(link);
                link.click();
            } catch (e) {
                ElMessage({
                    message: 'Error exporting!',
                    type: 'error',
                })
            }
        },
        async getTier1Companies({commit}, filters) {
            try {
                const response = await api.getTier1Companies(filters);
                commit('setTier1Companies', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getTier2Companies({commit}, filters) {
            try {
                const response = await api.getTier2Companies(filters);
                commit('setTier2Companies', response);
            } catch (e) {
                console.log(e)
            }
        },
        async getChildCompanies({commit}, filters) {
            try {
                const response = await api.getChildCompanies(filters);
                commit('setChildCompanies', response);
            } catch (e) {
                console.log(e)
            }
        },
    }
}
