/* eslint-disable */
import {createWebHistory, createRouter} from "vue-router";

// layouts
import Admin from "@/views/layouts/Admin.vue";
import Auth from "@/views/layouts/Auth.vue";
import SocialLogin from "@/views/auth/SocialLogin.vue";

// views for Admin layout
import Dashboard from "@/views/admin/Dashboard.vue";
import Companies from "@/views/admin/Companies.vue";
import Vehicles from "@/views/Companies/Vehicles.vue";
import Tyres from "@/views/Companies/Tyres.vue"
import TyreSettings from "@/views/admin/TyreSettings.vue";
import Towns from "@/views/admin/Towns.vue";
import Manufacturers from "@/views/admin/Manufacturers.vue";
import Routes from "@/views/admin/Routes.vue";
import Users from "@/views/admin/Users.vue";
import Fleets from "@/views/admin/Fleets.vue";
import CompanyVehicles from "@/views/Companies/CompanyVehicles.vue";
import CompanyRoutes from "@/views/Companies/CompanyRoutes.vue";
import Roles from "@/views/admin/Roles.vue";
import RolePermissions from "@/views/admin/RolePermissions.vue";
import CompanyCommodities from "@/views/Companies/CompanyCommodities.vue";
import CompanyTowns from "@/views/Companies/CompanyTowns.vue";
import Commodities from "@/views/admin/Commodities.vue"
import Permissions from "@/views/admin/Permissions.vue"
import AppSettings from "@/views/admin/AppSettings.vue"


// views for Auth layout
import Login from "@/views/auth/Login.vue";
import Register from "@/views/auth/Register.vue";
import ForgotPassword from "@/views/auth/ForgotPassword.vue";
import VerifyEmail from "@/views/auth/VerifyEmail.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import PrivacySettings from "@/views/auth/PrivacySettings.vue"

// views for company
import Index from "@/views/Index.vue";
import VerifyAccount from "@/views/auth/VerifyAccount.vue";
import Inspections from "@/views/Companies/Inspections/Inspections.vue";
import InspectionHistory from "@/views/Companies/Inspections/InspectionHistory.vue";
import InspectionQuestionCreate from "@/views/Companies/Inspections/InspectionQuestionCreate.vue";
import CompanyDashboard from "@/views/Companies/Dashboard.vue"
import InspectionDetail from "@/views/Companies/Inspections/InspectionDetail.vue";
import Defects from "@/views/Companies/Defects.vue";
import DefectDetails from "@/views/Companies/DefectDetails.vue";
import CompanyBranches from "@/views/Companies/CompanyBranches.vue"
import CompanyCustomers from "@/views/Companies/Customers.vue"
import TyreHistory from "@/views/Companies/TyreHistory.vue";
import AllTyreHistory from "@/views/Companies/AllTyreHistory.vue"
import SmsUsers from "@/views/Companies/SmsUsers.vue";
import Journeys from "@/views/Companies/Journeys/Journeys.vue"
import CheckPoints from "@/views/Companies/Journeys/JourneyCheckpoints.vue"
import JourneyDetails from "@/views/Companies/Journeys/JourneyDetails.vue";
import Incentives from "@/views/Companies/Incentives/Incentives.vue";
import UserIncentives from "@/views/Companies/Incentives/UserIncentives.vue";
import JourneyHops from "@/views/Companies/Journeys/JourneyHops.vue";
import CompanyConfigs from "@/views/Companies/CompanyConfigs.vue";
import Trips from "@/views/Companies/Trips.vue";


// routes

const companyData = {
    "name": String,
    "physicalAddress": String,
    "email": String,
    "phone": String,
    "company_reg_number": String
}

const routes = [
    {
        path: "/admin",
        component: Admin,
        children: [
            {
                path: "/admin/dashboard",
                component: Dashboard,
                name: 'Dashboard',
                meta: {
                    auth: true,
                },
            },
            {
                path: "/admin/companies",
                component: Companies,
                // meta: {
                //     auth: true,
                //     key: 'view-companies',
                // },
            },
            {
                path: "/admin/vehicles",
                component: Vehicles,
                meta: {
                    auth: true,
                    key: 'view-vehicles',
                },
            },
            {
                path: "/admin/tyres/:id",
                component: Tyres,
                meta: {
                    auth: true,
                    key: 'view-company-tyres',
                },

            },
            {
                path: "/admin/tyre-settings",
                component: TyreSettings,
                meta: {
                    auth: true,
                    key: 'view-tyre-settings',
                },
            },
            {
                path: "/admin/routes",
                component: Routes,
                meta: {
                    auth: true,
                    // key: 'view-routes',
                },
            },
            {
                path: "/admin/branches/:id",
                component: CompanyBranches,
                meta: {
                    auth: true,
                    // key: 'view-routes',
                },
            },
            {
                path: "/admin/fleets",
                component: Fleets,
                meta: {
                    auth: true,
                    key: 'view-fleets',
                },
            },
            {
                path: "/admin/vehicles/:id",
                component: CompanyVehicles,
                meta: {
                    auth: true,
                    key: 'view-company-vehicles',
                },
            },
            {
                path: "/admin/routes/:id",
                component: Routes,
                meta: {
                    auth: true,
                    key: 'view-company-routes',
                },
            },
            {
                path: "/admin/commodities",
                component: Commodities,
                meta: {
                    auth: true,
                    // key: 'view-company-commodities'
                },
            },
            {
                path: "/admin/commodities/:id",
                component: CompanyCommodities,
                meta: {
                    auth: true,
                    key: 'view-company-commodities',
                },
            },
            {
                path: "/admin/roles",
                component: Roles,
                meta: {
                    auth: true,
                    key: 'view-roles-menu',
                },
            },
            {
                path: "/admin/permissions",
                component: Permissions,
                meta: {
                    auth: true,
                    // key: 'view-roles-menu',
                },
            },
            {
                path: "/admin/role-permissions/:id",
                component: RolePermissions,
            },
            {
                path: "/admin/users",
                component: Users,
                meta: {
                    auth: true,
                    key: 'view-users-menu',
                },
            },
            {
                path: "/admin/towns",
                name: 'Towns',
                component: Towns,
                meta: {
                    auth: true,
                    // key: 'view-towns'
                },
            },
            {
                path: "/admin/towns/:id",
                component: CompanyTowns,
                meta: {
                    auth: true,
                    // key: 'view-company-routes',
                },
            },
            {
                path: "/admin/app-settings",
                component: AppSettings,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/admin/configs/:id",
                component: CompanyConfigs,
                meta: {
                    auth: true,
                },
            },
        ],
    },
    {
        path: "/",
        component: Admin,
        meta:{
          auth:true
        },
        children: [
            {
                path: "/dashboard",
                name: 'CompanyDashboard',
                component: CompanyDashboard,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/trips",
                name: 'Trips',
                component: Trips,
                meta: {
                    auth: true,
                    // key: 'view-company-vehicles'
                },
            },
            {
                path: "/vehicles",
                name: 'Vehicles',
                component: CompanyVehicles,
                meta: {
                    auth: true,
                    key: 'view-company-vehicles'
                },
            },
            {
                path: "/routes",
                name: 'Routes',
                component: Routes,
                meta: {
                    auth: true,
                    key: 'view-company-routes'
                },
            },
            {
                path: "/commodities",
                name: 'Commodities',
                component: CompanyCommodities,
                meta: {
                    auth: true,
                    key: 'view-company-commodities'
                },
            },
            {
                path: "/towns",
                name: 'CompanyTowns',
                component: CompanyTowns,
                meta: {
                    auth: true,
                    key: 'view-company-towns'
                },
            },
            {
                path: "/users",
                name: 'Users',
                component: Users,
                meta: {
                    auth: true,
                    key: 'view-company-users-menu'
                },
            },
            {
                path: "/roles",
                name: 'Roles',
                component: Roles,
                meta: {
                    auth: true,
                    key: 'view-company-roles-menu'
                },
            },
            {
                path: "/role-permissions/:id",
                name: 'RolePermissions',
                component: RolePermissions,
                meta: {
                    auth: true,
                }
            },
            {
                path: "/tyre-settings",
                component: TyreSettings,
                meta: {
                    auth: true,
                    key: 'view-tyre-settings',
                },
            },
            {
                path: "/tyres",
                component: Tyres,
                meta: {
                    auth: true,
                    key: 'view-company-tyres',
                },

            },
            {
                path: "/inspections",
                component: Inspections,
                meta: {
                    auth: true,
                },

            },
            {
                path: "/inspection-history",
                component: InspectionHistory,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/inspection-detail/:id",
                component: InspectionDetail,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/question-create",
                component: InspectionQuestionCreate,
                meta: {
                    auth: true,
                },

            },
            {
                path: "/question-edit/:id",
                component: InspectionQuestionCreate,
                meta: {
                    auth: true,
                },

            },
            {
                path: "/defects",
                component: Defects,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/defect-details/:id",
                component: DefectDetails,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/branches",
                component: CompanyBranches,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/customers",
                component: CompanyCustomers,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/tyres/:id",
                component: TyreHistory,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/tyre-history",
                component: AllTyreHistory,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/sms-users",
                component: SmsUsers,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/journeys",
                component: Journeys,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/journey/:id",
                component: JourneyDetails,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/checkpoints/:id",
                component: CheckPoints,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/incentives",
                component: Incentives,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/user-incentives",
                component: UserIncentives,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/manufacturers",
                component: Manufacturers,
                meta: {
                    auth: true,
                    // key: 'view-manufactures',
                },
            },
            {
                path: "/journey-hops",
                component: JourneyHops,
                meta: {
                    auth: true,
                },
            },
            {
                path: "/configs",
                component: CompanyConfigs,
                meta: {
                    auth: true,
                },
            },
        ],
    },
    {
        path: "/",
        redirect: "/login",
        component: Auth,
        children: [
            {
                path: "/login",
                name: 'login',
                component: Login,
                meta: {
                    guest: true,
                }
            },
            {
                path: "/register",
                name: 'register',
                component: Register,
                meta: {
                    guest: true,
                }
            },
            {
                path: "/forgot-password",
                name: 'forgot-password',
                component: ForgotPassword,
                meta: {
                    guest: true,
                }
            },
            {
                path: "/verify-email",
                name: 'verify-email',
                component: VerifyEmail,
                meta: {
                    guest: true,
                }
            },
            {
                path: "/reset-password",
                name: 'reset-password',
                component: ResetPassword,
                meta: {
                    guest: true,
                }
            },
            {
                path: "/verify-account",
                name: 'verify-account',
                component: VerifyAccount,
                meta: {
                    guest: true,
                }
            },
        ],
    },
    {
        path: "/privacy-settings",
        name: 'privacy-settings',
        component: PrivacySettings,
        meta: {
            guest: true,
        }
    },
    {
        path: "/callback:code?",
        component: SocialLogin,
    },
    {
        path: "/:catchAll(.*)",
        name: "",
        component: Dashboard,
        meta: {
            auth: true
        }
    }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    setTimeout(() => {
        const user = JSON.parse(localStorage.getItem('user'));
        const permissions = JSON.parse(localStorage.getItem('permissions'));
        console.log(user, to)

        if (to.meta.auth && !user) {
            console.log('called')
            window.location.href = '/login';
        } else {
            if (to.meta.key && permissions.length && permissions.filter(i => i.name === to.meta.key) < 1) {
                if (!user.is_admin) {
                    window.location.href = '/dashboard';
                } else {
                    window.location.href = '/admin/dashboard';
                }
                return next(false);
            }
            return next();
        }
    }, 1000);
});

export default router
