import client from "@/data/api/client";

export default {
    async getTrips(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/get-trips', filters)));
    },
    async editTrip(data) {
        return client.parseResponse(await client.put('/update-trip/' + data.id, data))
    },
    async deleteTrip(payload) {
        return client.parseResponse(await client.delete('/delete-trip/' + payload.id));
    },
    async getIntermediateTrips(data) {
        return client.parseResponse(client.parseResponse(await client.get('/intermediate-trips/' + data.id, data)));
    },
    async exportTrips(filters) {
        return await client.get('/export-trips', filters, {responseType: 'blob'});
    },
}
