<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div
                    class="md:flex-row flex-col relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Trips
                    </h3>

                </div>
            </div>
        </div>
        <!--    Filters and Search-->
        <div class="flex flex-col py-6 px-6 space-y-2 md:space-x-4 space-x-0">

            <div class="flex lg:flex-row flex-col justify-between md:items-start ">
                <div class="block">
                    <span class="text-black mr-2">Date Range</span>
                    <el-date-picker
                        v-model="date_range"
                        type="datetimerange"
                        :shortcuts="shortcuts"
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                    />
                </div>
                <form>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="w-5 h-5 mr-3">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <input v-model="search"
                               class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                               placeholder="Search Trips ...">
                    </div>
                </form>
            </div>

        </div>


        <div class="block w-full overflow-x-auto">

            <!-- View dialog-->
            <el-dialog
                v-model="viewTripDialogVisible"
                title="View Intermediate Trips"
                :width="windowWidth > 768 ? '70%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <div class="py-8 px-4">
                        <el-table class="" :data="intermediate_trips.data" stripe size="large" style="width: 100%">
                            <el-table-column prop="service_name" label="Service Name"/>
                            <el-table-column prop="destination" label="Destination"/>
                            <el-table-column prop="town.name" label="Town"/>
                            <el-table-column prop="fuel_obc" label="Fuel OBS"/>
                            <el-table-column prop="odometer" label="ODOMETER"/>
                            <el-table-column prop="endedAt" label="Ended At"/>
                            <el-table-column prop="lat" label="Lat"/>
                            <el-table-column prop="long" label="Long"/>
                            <el-table-column label="ACTIONS">
                                <template #default="scope">
                                    <el-dropdown size="large">
                                        <el-button>
                                            Actions
                                            <el-icon class="el-icon--right">
                                                <arrow-down/>
                                            </el-icon>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click.native="editIntermediateTrip(scope.row)">EDIT
                                                </el-dropdown-item>
<!--                                                <el-dropdown-item @click.native="deleteTrip(scope.row)">DELETE-->
<!--                                                </el-dropdown-item>-->
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="flex flex-row p-5 w-full justify-end">
                        <el-pagination
                            :current-page="intermediate_trips.current_page"
                            :page-size="intermediate_trips.per_page"
                            :page-sizes="[5,10,20,50]"
                            :small=false
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="intermediate_trips.total"
                            @size-change="handleIntermediateSizeChange"
                            @current-change="handleIntermediateCurrentChange"
                        />
                    </div>
                </div>
            </el-dialog>

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="editIntermediateTripDialogVisible"
                title="Edit Trips"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Service Name
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="intermediate_trip.service_name" placeholder="Select tyre size"
                                        size="default">
                                        <el-option>Trip Start</el-option>
                                        <el-option>Customer</el-option>
                                        <el-option>Loading</el-option>
                                        <el-option>Refuel</el-option>
                                        <el-option>End Trip</el-option>
                                        <el-option>Wrong End Trip</el-option>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Town
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="intermediate_trip.town_id" placeholder="Select town"
                                        size="default">
                                        <el-option
                                            v-for="item in towns"
                                            :key="item.id"
                                            :label="item.town ? item.town.name : item.town_id"
                                            :value="item.town_id"
                                        />
                                    </el-select>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.town_id  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.town_id[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Fuel OBC
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Fuel Obc"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="intermediate_trip.fuel_obc" type="text"
                                        name="form.name"/>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.fuel_obc  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.fuel_obc[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Odometer
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Odometer"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="intermediate_trip.odometer" type="text"
                                        name="form.name"/>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.odometer  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.odometer[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Latitude
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="latitude"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="intermediate_trip.lat" type="text"
                                        name="form.name"/>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.lat  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.lat[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Longitude
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="longitude"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="intermediate_trip.long" type="text"
                                        name="form.name"/>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.long  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.long[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addIntermediateTrip">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="editIntermediateTripDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>

            <!-- Add/Edit dialog-->
            <el-dialog
                v-model="editTripDialogVisible"
                title="Edit Trips"
                :width="windowWidth > 768 ? '30%' : '90%'">
                <div class="flex flex-col gap-y-2">
                    <form class="px-4">
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Town
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.town_id" placeholder="Select town"
                                        size="default">
                                        <el-option
                                            v-for="item in towns"
                                            :key="item.id"
                                            :label="item.town ? item.town.name : item.town_id"
                                            :value="item.town_id"
                                        />
                                    </el-select>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.town_id  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.town_id[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Vehicle
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.vehicle_id" placeholder="Select vehicle"
                                        size="default">
                                        <el-option
                                            v-for="item in vehicles"
                                            :key="item.id"
                                            :label="item.reg_number"
                                            :value="item.id"
                                        />
                                    </el-select>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.vehicle_id  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.vehicle_id[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Commodity
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.commodity_id" placeholder="Select commodity"
                                        size="default">
                                        <el-option
                                            v-for="item in commodities"
                                            :key="item.id"
                                            :label="item.commodity ? item.commodity.name : item.commodity_id"
                                            :value="item.id"
                                        />
                                    </el-select>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.commodity_id  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.commodity_id[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Odometer
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Odometer"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.odometer" type="text"
                                        name="form.name"/>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.odometer  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.odometer[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Latitude
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="latitude"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.lat" type="text"
                                        name="form.name"/>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.lat  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.lat[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Longitude
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="longitude"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.long" type="text"
                                        name="form.name"/>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.long  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.long[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Fuel OBC
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Fuel Obc"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.fuel_obc" type="text"
                                        name="form.name"/>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.fuel_obc  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.fuel_obc[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Tonnage
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <input
                                        placeholder="Tonnage"
                                        class="py-1 text-slate-600 relative bg-white rounded text-sm border border-gray-200 outline-none focus:outline-none focus:border-gray-500 w-full"
                                        v-model="form.tonnage" type="text"
                                        name="form.name"/>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.tonnage  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.tonnage[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Route
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.route_id" placeholder="Select route"
                                        size="default">
                                        <el-option
                                            v-for="item in routes"
                                            :key="item.id"
                                            :label="item.Route_Code"
                                            :value="item.id"
                                        />
                                    </el-select>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.route_id  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.route_id[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Driver
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.driver_id" placeholder="Select driver"
                                        size="default">
                                        <el-option
                                            v-for="item in users"
                                            :key="item.id"
                                            :label="item.full_name"
                                            :value="item.id"
                                        />
                                    </el-select>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.driver_id  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.driver_id[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col items-start justify-start mb-3 w-full">
                            <p class="text-md font-bold text-sm lg:text-md mb-3">Status
                                <span class="text-red-500 ml-2">*</span>
                            </p>
                            <div class="flex flex-row items-start justify-start w-full mb-1">
                                <div class="flex flex-col rounded-md w-full">
                                    <el-select
                                        style="width: 100%" v-model="form.status" placeholder="Select status"
                                        size="default">
                                        <el-option value="open">Open</el-option>
                                        <el-option value="ended">Ended</el-option>
                                    </el-select>
                                    <!--                                    <span class="text-red-500"-->
                                    <!--                                          v-if="errors.data.web_errors.status  && this.has_errors">-->
                                    <!--                                        {{ errors.data.web_errors.status[0] }}-->
                                    <!--                                    </span>-->
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="dialog-footer flex flex-row w-full justify-end px-4">
                        <el-button class="bg-sky-600 text-white" @click="addTrip">
                            Save
                        </el-button>
                        <el-button class="bg-red-700 text-white" type="danger"
                                   @click="editTripDialogVisible = false">Cancel
                        </el-button>
                    </div>
                </div>
            </el-dialog>


            <el-tabs v-model="activeTab" type="border-card" @tab-click="handleTabClick">
                <div class="flex flex-row justify-end pt-6">
                    <el-button @click="exportTrips" type="success" plain size="large">
                        <span class="mr-3">Download Trips Excel</span>
                        <el-icon>
                            <Download/>
                        </el-icon>
                    </el-button>
                </div>
                <el-tab-pane name="All">
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                            </el-icon>
                            All
                        </div>
                    </template>
                    <!--Table-->
                    <div class="py-8 px-4">
                        <el-table class="" :data="trips.data" stripe size="large" style="width: 100%">
                            <el-table-column prop="startedOn" label="STARTED AT"/>
                            <el-table-column prop="endedAt" label="ENDED AT"/>
                            <el-table-column prop="vehicle.reg_number" label="VEHICLE"/>
                            <el-table-column prop="commodity.name" label="COMMODITY"/>
                            <el-table-column prop="driver.full_name" label="DRIVER"/>
                            <el-table-column prop="distance_covered" label="DISTANCE COVERED"/>
                            <el-table-column prop="average_speed" label="AVERAGE SPEED"/>
                            <el-table-column prop="actual_fuel_consumed" label="ACTUAL FUEL CONSUMED"/>
                            <el-table-column prop="odometer" label="ODOMETER"/>
                            <el-table-column label="STATUS">
                                <template #default="scope">
                                    <el-tag effect="dark"
                                            :type="scope.row.status === 'open' ? 'warning' : scope.row.status === 'ended' ? '' : 'success'">
                                        {{ scope.row.status }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="route.Route_Code" label="ROUTE"/>
                            <el-table-column label="ACTIONS">
                                <template #default="scope">
                                    <el-dropdown size="large">
                                        <el-button>
                                            Actions
                                            <el-icon class="el-icon--right">
                                                <arrow-down/>
                                            </el-icon>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click.native="editTrip(scope.row)">EDIT
                                                </el-dropdown-item>
                                                <el-dropdown-item @click.native="viewTrip(scope.row)">VIEW
                                                </el-dropdown-item>
                                                <el-dropdown-item @click.native="deleteTrip(scope.row)">DELETE
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="flex flex-row p-5 w-full justify-end">
                        <el-pagination
                            :current-page="trips.current_page"
                            :page-size="trips.per_page"
                            :page-sizes="[5,10,20,50]"
                            :small=false
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="trips.total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </div>
                </el-tab-pane>
                <el-tab-pane name="open">
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                            </el-icon>
                            Open
                        </div>
                    </template>
                    <!--Table-->
                    <div class="py-8 px-4">
                        <el-table class="" :data="trips.data" stripe size="large" style="width: 100%">
                            <el-table-column prop="startedOn" label="STARTED AT"/>
                            <el-table-column prop="endedAt" label="ENDED AT"/>
                            <el-table-column prop="vehicle.reg_number" label="VEHICLE"/>
                            <el-table-column prop="commodity.name" label="COMMODITY"/>
                            <el-table-column prop="driver.full_name" label="DRIVER"/>
                            <el-table-column prop="distance_covered" label="DISTANCE COVERED"/>
                            <el-table-column prop="average_speed" label="AVERAGE SPEED"/>
                            <el-table-column prop="actual_fuel_consumed" label="ACTUAL FUEL CONSUMED"/>
                            <el-table-column prop="odometer" label="ODOMETER"/>
                            <el-table-column label="STATUS">
                                <template #default="scope">
                                    <el-tag effect="dark"
                                            :type="scope.row.status === 'open' ? 'warning' : scope.row.status === 'ended' ? '' : 'success'">
                                        {{ scope.row.status }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="route.Route_Code" label="ROUTE"/>
                            <el-table-column label="ACTIONS">
                                <template #default="scope">
                                    <el-dropdown size="large">
                                        <el-button>
                                            Actions
                                            <el-icon class="el-icon--right">
                                                <arrow-down/>
                                            </el-icon>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click.native="editTrip(scope.row)">EDIT
                                                </el-dropdown-item>
                                                <el-dropdown-item @click.native="viewTrip(scope.row)">VIEW
                                                </el-dropdown-item>
                                                <el-dropdown-item @click.native="deleteTrip(scope.row)">DELETE
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="flex flex-row p-5 w-full justify-end">
                        <el-pagination
                            :current-page="trips.current_page"
                            :page-size="trips.per_page"
                            :page-sizes="[5,10,20,50]"
                            :small=false
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="trips.total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </div>
                </el-tab-pane>
                <el-tab-pane name="ended">
                    <template #label>
                        <div class="flex flex-row justify-center items-center">
                            <el-icon class="mr-2">
                            </el-icon>
                            Ended
                        </div>
                    </template>
                    <!--Table-->
                    <div class="py-8 px-4">
                        <el-table class="" :data="trips.data" stripe size="large" style="width: 100%">
                            <el-table-column prop="startedOn" label="STARTED AT"/>
                            <el-table-column prop="endedAt" label="ENDED AT"/>
                            <el-table-column prop="vehicle.reg_number" label="VEHICLE"/>
                            <el-table-column prop="commodity.name" label="COMMODITY"/>
                            <el-table-column prop="driver.full_name" label="DRIVER"/>
                            <el-table-column prop="distance_covered" label="DISTANCE COVERED"/>
                            <el-table-column prop="average_speed" label="AVERAGE SPEED"/>
                            <el-table-column prop="actual_fuel_consumed" label="ACTUAL FUEL CONSUMED"/>
                            <el-table-column prop="odometer" label="ODOMETER"/>
                            <el-table-column label="STATUS">
                                <template #default="scope">
                                    <el-tag effect="dark"
                                            :type="scope.row.status === 'open' ? 'warning' : scope.row.status === 'ended' ? '' : 'success'">
                                        {{ scope.row.status }}
                                    </el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="route.Route_Code" label="ROUTE"/>
                            <el-table-column label="ACTIONS">
                                <template #default="scope">
                                    <el-dropdown size="large">
                                        <el-button>
                                            Actions
                                            <el-icon class="el-icon--right">
                                                <arrow-down/>
                                            </el-icon>
                                        </el-button>
                                        <template #dropdown>
                                            <el-dropdown-menu>
                                                <el-dropdown-item @click.native="editTrip(scope.row)">EDIT
                                                </el-dropdown-item>
                                                <el-dropdown-item @click.native="viewTrip(scope.row)">VIEW
                                                </el-dropdown-item>
                                                <el-dropdown-item @click.native="deleteTrip(scope.row)">DELETE
                                                </el-dropdown-item>
                                            </el-dropdown-menu>
                                        </template>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="flex flex-row p-5 w-full justify-end">
                        <el-pagination
                            :current-page="trips.current_page"
                            :page-size="trips.per_page"
                            :page-sizes="[5,10,20,50]"
                            :small=false
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="trips.total"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                        />
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import {ArrowDown, Download} from "@element-plus/icons-vue";
// import {ElMessage} from "element-plus";
import moment from "moment/moment";

export default {
    components: {ArrowDown, Download},
    data() {
        return {
            search: '',
            windowWidth: window.innerWidth,
            editTripDialogVisible: false,
            viewTripDialogVisible: false,
            editIntermediateTripDialogVisible: false,
            form: {},
            intermediate_trip: {},
            user: {},
            filters: {
                paginate: true,
                per_page: 10
            },
            activeTab: 'All',
            date_range: [],
            shortcuts: [
                {
                    text: 'Today',
                    value: () => {
                        let start = new Date();
                        start.setHours(0, 0, 0, 0); // Start of today (midnight)

                        let end = new Date();
                        end.setHours(23, 59, 59, 999); // End of today
                        console.log(start, end)
                        return [start, end];
                    },
                },
                {
                    text: 'Yesterday',
                    value: () => {
                        let start = new Date();
                        start.setDate(start.getDate() - 1);
                        start.setHours(0, 0, 0, 0); // Start of yesterday (midnight)

                        let end = new Date();
                        end.setDate(end.getDate() - 1);
                        end.setHours(23, 59, 59, 999); // End of yesterday
                        console.log(start, end)
                        return [start, end];
                    },
                },
                {
                    text: 'This week',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setHours(0, 0, 0, 0); // Start of the current day
                        const dayOfWeek = start.getDay(); // 0 (Sunday) to 6 (Saturday)
                        const daysToSubtract = (dayOfWeek + 6) % 7;
                        start.setDate(start.getDate() - daysToSubtract);
                        return [start, end];
                    },
                },
                {
                    text: 'Last week',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]

                    },
                },
                {
                    text: 'This month',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setDate(1); // Start of the current month
                        return [start, end];
                    },
                },
                {
                    text: 'Last month',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        return [start, end]
                    },
                },
                {
                    text: 'Last 3 months',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                        return [start, end]
                    },
                },
                {
                    text: 'This year',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(0, 1); // Start of the current year
                        return [start, end];
                    },
                },
            ]
        };
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getTripsList({
            ...this.filters,
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
        this.getRoutes({
            company_id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
        this.getCompanyCommodities({
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
        this.getUsers({
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
        this.getVehicles({
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
        this.getCompanyTowns({
            id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        })
    },
    computed: {
        ...mapGetters({
            errors: "trips/errors",
            has_errors: 'trips/has_errors',
            trips: 'trips/trips',
            intermediate_trips: 'trips/intermediate_trips',
            vehicles: 'vehicles/vehicles',
            towns: 'towns/company_towns',
            commodities: 'company_commodities/company_commodities',
            routes: 'routes/routes',
            users: 'users/users',
        }),
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getTripsList({
                    ...this.filters,
                    id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
                })
            }
        },
        search: function (val) {
            this.getTripsList({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id,
                search: val
            });
        },
        'date_range': function (val) {
            this.filters.start = moment(val[0]).format('DD-MM-YYYY HH:MM')
            this.filters.end = moment(val[1]).format('DD-MM-YYYY HH:MM')
            this.getTripsList({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id,
            })
        },

    },
    methods: {
        ...mapActions({
            getTripsList: 'trips/getTrips',
            downloadTrips: 'trips/exportTrips',
            getIntermediateTrips: 'trips/getIntermediateTrips',
            updateTrip: 'trips/editTrip',
            removeTrip: 'trips/deleteTrip',
            getVehicles: 'vehicles/getVehicles',
            getCompanyTowns: 'towns/getCompanyTowns',
            getCompanyCommodities: 'company_commodities/getCompanyCommodities',
            getRoutes: 'routes/getRoutes',
            getUsers: 'users/getUsers',
        }),

        handleTabClick(tab, event) {
            console.log(event, tab)
            if (tab.props.name === 'All') {
                this.filters.status = ''
            } else if (tab.props.name === 'ended') {
                this.filters.status = 'ended'
            } else {
                this.filters.status = 'open'
            }
            localStorage.setItem('activeTab', this.filters.status);
            this.getTripsList({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            })
        },
        exportTrips() {
            this.filters.status = localStorage.getItem('activeTab')
            this.downloadTrips({
                ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            });
        },
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getTripsList({
                paginate: true, ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            });
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getTripsList({
                paginate: true, ...this.filters,
                id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
            });
        },
        // handleIntermediateSizeChange(size) {
        //     this.filters.per_page = size;
        //     this.getIntermediateTrips({
        //         paginate: true, ...this.filters,
        //         id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        //     });
        // },
        // handleIntermediateCurrentChange(page) {
        //     this.filters.page = page;
        //     this.getIntermediateTrips({
        //         paginate: true, ...this.filters,
        //         id: this.$route.params.id ? this.$route.params.id : this.user.user_company.company_id
        //     });
        // },
        deleteTrip(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.removeTrip({
                    id: item.id,
                })
            }
        },
        editTrip(item) {
            this.editTripDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        viewTrip(item) {
            this.viewTripDialogVisible = true
            this.getIntermediateTrips({
                paginate: true,
                id: item.id
            })
        },
        addTrip() {
            this.updateTrip(this.form).then(() => {
                if (!this.has_errors) {
                    this.form = {}
                    this.editTripDialogVisible = false
                }
            })
        },
        addIntermediateTrip() {
            this.updateTrip(this.intermediate_trip).then(() => {
                if (!this.has_errors) {
                    this.form = {}
                    this.editIntermediateTripDialogVisible = false
                }
            })
        },
        editIntermediateTrip(item) {
            this.editIntermediateTripDialogVisible = true
            this.intermediate_trip = Object.assign({}, this.intermediate_trip, item);
        },
        // deleteIntermediateTrip(item){
        //
        // }
    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
