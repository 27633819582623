import client from "./client";

export default {
    async getUsers(filters) {
        return client.parseResponse(client.parseResponse(await client.get('/users', filters)));
    },

    async createUsers(data) {
        return client.parseResponse(await client.post('/users', data))
    },

    async getUser(id) {
        return client.parseResponse(await client.get('/users/' + id));
    },

    async editUser(data) {
        return client.parseResponse(await client.put('/users/' + data.id, data))
    },

    async deleteUser(payload) {
        return client.parseResponse(await client.delete('/users/' + payload.id));
    },

    async uploadUsers(payload) {
        return client.parseResponse(await client.upload('/import-users', payload, payload.file))
    },

    async exportUsers(filters) {
        return await client.get('/export-users', filters, {responseType: 'blob'});
    },
    async getUserRoles(data) {
        return client.parseResponse(await client.get('/user-roles/' + data.id,data));
    },
    async saveUserRoles(data) {
        return client.parseResponse(await client.post('/user-roles', data));
    },
    async disableUser(data) {
        return client.parseResponse(await client.put('/disable-user/' + data.id, data))
    },
}
