<template>
    <div class="flex flex-col min-w-0 break-words w-full mb-6 shadow-md rounded border bg-white">

        <div class="rounded-t mb-0 px-4 py-5 border-b">
            <div class="flex flex-wrap items-center">
                <div class="relative w-full px-4 inline-flex justify-between max-w-full flex-grow flex-1">
                    <h3 class="font-semibold text-lg">
                        Inspection History
                    </h3>
                </div>
            </div>
        </div>

        <!--    Filters and Search-->
        <div class="flex flex-col text-gray-100 border-b px-4 py-4 justify-between">
            <div class="flex flex-row justify-between px-6">
                <div class="block">
                    <span class="text-black mr-2">Date Range</span>
                    <el-date-picker
                        v-model="date_range"
                        type="datetimerange"
                        :shortcuts="shortcuts"
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                    />
                </div>
                <el-button @click="exportInspectionLogs" type="success" plain size="large">
                    <span class="mr-3">Export Inspection History</span>
                    <el-icon>
                        <Download/>
                    </el-icon>
                </el-button>
            </div>
            <div class="flex lg:flex-row flex-col justify-between md:items-start md:space-y-0 space-y-2 md:space-x-4 space-x-0 pt-6">
                <div class="flex flex-row justify-start items-end mb-3 space-x-4 text-black px-5">
                    <el-tabs v-model="activeName" class="p-3 font-semibold text-xl" @tab-click="handleClick">
                        <el-tab-pane label="All" name="all"></el-tab-pane>
                        <el-tab-pane label="Passed" name="passed"></el-tab-pane>
                        <el-tab-pane label="Failed" name="failed"></el-tab-pane>
                    </el-tabs>

                </div>
                <!--      search-->
                <form>
                    <div class="relative">
                        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                                 stroke="currentColor" class="w-5 h-5 mr-3">
                                <path stroke-linecap="round" stroke-linejoin="round"
                                      d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
                            </svg>
                        </div>
                        <input v-model="search"
                               class="block outline-none w-full px-12 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary"
                               placeholder="Search Inspection history ...">
                    </div>
                </form>
            </div>
        </div>

        <div class="block w-full overflow-x-auto">

            <!--Table-->
            <div class="py-8 px-4">
                <el-table class="" :data="inspection_logs.data" stripe size="large" style="width: 100%">
                    <el-table-column prop="inspection.company.name" label="COMPANY"/>
                    <el-table-column label="STATUS">
                        <template #default="scope">
                            <el-tag effect="dark" :type="scope.row.status === 1 ? 'success' : 'danger'">
                                {{ scope.row.status === 1 ? 'Passed' : 'Failed' }}
                            </el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="created_at" label="DATE INSPECTED"/>
                    <el-table-column sortable prop="vehicle.reg_number" label="VEHICLE REGISTRATION NO"/>
                    <el-table-column sortable prop="user.first_name" label="ADDED BY"/>
                    <el-table-column sortable prop="inspection.title" label="INSPECTION FORM"/>
                    <el-table-column label="ACTIONS">
                        <template #default="scope">
                            <el-button size="default" @click="viewInspectionDetail(scope.row)"  circle>
                                <el-icon>
                                    <DArrowRight/>
                                </el-icon>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="flex flex-row p-5 w-full justify-end">
                <el-pagination
                    :current-page="inspection_logs.current_page"
                    :page-size="inspection_logs.per_page"
                    :page-sizes="[5,10,20,50]"
                    :small=false
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="inspection_logs.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                />
            </div>
        </div>

    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import {DArrowRight, Download} from '@element-plus/icons-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import moment from "moment";

export default {
    data() {
        return {
            search: '',
            uploadInspectionLogDialogVisible: false,
            addInspectionLogDialogVisible: false,
            windowWidth: window.innerWidth,
            form: {},
            inspection_log: {},
            filters: {
                paginate: true,
                per_page: 10
            },
            date_range:[],
            activeName: 'all',
            shortcuts:[
                {
                    text: 'Today',
                    value: new Date(),
                },
                {
                    text: 'Yesterday',
                    value: () => {
                        const date = new Date()
                        date.setTime(date.getTime() - 3600 * 1000 * 24)
                        return date
                    },
                },
                {
                    text: 'This week',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setHours(0, 0, 0, 0); // Start of the current day
                        const dayOfWeek = start.getDay(); // 0 (Sunday) to 6 (Saturday)
                        const daysToSubtract = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
                        start.setDate(start.getDate() - daysToSubtract);
                        return [start, end];
                    },
                },
                {
                    text: 'Last week',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
                        return [start, end]
                    },
                },
                {
                    text: 'This month',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setDate(1); // Start of the current month
                        return [start, end];
                    },
                },
                {
                    text: 'Last month',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
                        return [start, end]
                    },
                },
                {
                    text: 'Last 3 months',
                    value: () => {
                        const end = new Date()
                        const start = new Date()
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
                        return [start, end]
                    },
                },
                {
                    text: 'This year',
                    value: () => {
                        const end = new Date();
                        const start = new Date();
                        start.setMonth(0, 1); // Start of the current year
                        return [start, end];
                    },
                },
            ]
        }
    },
    components: {
        DArrowRight,
        Download

    },
    mounted() {
        this.getInspectionLogsList(this.filters)
    },
    computed: {
        ...mapGetters({
            inspection_logs: 'inspection_logs/inspection_logs',
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.getInspectionLogsList(this.filters)
            }
        },
        'date_range': function (val){
            this.filters.start = moment(val[0]).format('DD-MM-YYYY HH:MM')
            this.filters.end = moment(val[1]).format('DD-MM-YYYY HH:MM')
            this.getInspectionLogsList(this.filters)
        },
        search: function (val) {
            this.getInspectionLogsList({
                ...this.filters,
                search: val
            });
        }
    },
    methods: {
        ...mapActions({
            getInspectionLogsList: 'inspection_logs/getInspectionLogs',
            createInspectionLogs: 'inspection_logs/createInspectionLogs',
            editInspectionLog: 'inspection_logs/editInspectionLog',
            removeInspectionLog: 'inspection_logs/deleteInspectionLog',
            downloadInspectionLogs:'inspection_logs/exportInspectionLogs'
        }),
        handleClick(tab) {
            if (tab.props.name === 'passed') {
                this.filters.status = 1
                this.getInspectionLogsList(this.filters)
            } else if (tab.props.name === 'failed') {
                this.filters.status = 0
                this.getInspectionLogsList(this.filters)
            } else if (tab.props.name === 'all') {
                this.filters.status = ''
                this.getInspectionLogsList(this.filters)
            }
        },
        handleSizeChange(size) {
            this.filters.per_page = size;
            this.getInspectionLogsList({paginate: true, ...this.filters});
        },
        handleCurrentChange(page) {
            this.filters.page = page;
            this.getInspectionLogsList({paginate: true, ...this.filters});
        },
        addInspectionLog() {
            if (this.form.id) {
                this.editInspectionLog(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addInspectionLogDialogVisible = false
                    }
                })
            } else {
                this.createInspectionLogs(this.form).then(() => {
                    if (!this.has_errors) {
                        this.form = {}
                        this.addInspectionLogDialogVisible = false
                    }
                });
            }
        },
        updateInspectionLog(item) {
            this.addInspectionLogDialogVisible = true
            this.form = Object.assign({}, this.form, item);
        },
        deleteInspectionLog(item) {
            let conf = confirm(`Are you sure you want to remove this item?`)
            if (conf) {
                this.removeInspectionLog({
                    id: item.id
                })
            }
        },
        exportInspectionLogs() {
            this.downloadInspectionLogs({...this.filters})
        },

        viewInspectionDetail(item) {
            this.$router.push('/inspection-detail/' + item.id)
        }

    }
};
</script>
<style scoped>
.example-showcase .el-dropdown-link {
    cursor: pointer;
    color: transparent;
    display: flex;
    align-items: center;
}
</style>
